import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
// import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
// import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import Swal from 'sweetalert2';
import { DialogContent } from '../modals/confirm.component';
import { UnsubscribeOnDestroyAdapter } from 'src/app/components/shared/UnsubscribeOnDestroyAdapter';
// import { EncuestaModel } from '../encuesta.model';
import { DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
// Service
import { UsuarioService } from 'src/app/core/service/usuario.service';
import { AsignacionEncuestasService } from 'src/app/core/service/asignacion-encuestas.service';
// Schemas
import { Usuario, User, Region, Comuna, Encuesta, EncuestaModel } from './form-encuesta-schemas'
// Utils
import { GeneralUtils } from 'src/app/core/utils/general.utils';
import { ContentObserver } from '@angular/cdk/observers';
import { timer } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-encuestas',
  templateUrl: './form-encuestas.component.html',
  styleUrls: ['./form-encuestas.component.scss']
})

export class FormEncuestasComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private asignacionEncuestasService: AsignacionEncuestasService,
    private generalUtils: GeneralUtils,
    // private readonly route: ActivatedRoute,
    public matModalDialog: MatDialog,
    // private readonly router: Router,
    public httpClient: HttpClient,
  ) { 
    super();
  }
  // @Output() eventData = new EventEmitter<any>();
  
  //tabla
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild('filter', { static: true }) filter: ElementRef;
  displayedColumns: string[] = ['idCadem', 'idOrigenLabel', 'fechaOrigen', 'fechaTope', 'estado', 'nomRegion', 'nomComuna', 'eliminarEncuesta'];
  // displayedColumns: string[] = ['idCadem', 'idOrigenLabel', 'fechaOrigen'];
  dataSource: EncuestasAsignadasDataSource | null;
  exampleDatabase: AsignacionEncuestasService | null;
  isTblLoading = true;
  // suscription: Subscription;
  // usId: any = parseInt(localStorage.getItem('idUsuario'));

  regionesSeleccionadas: any = [];
  
  // optionsUsers: string[] = [];

  // // suscription: Subscription;

  idPerfil : string = localStorage.getItem('idPerfil');
  idUsuario: string = localStorage.getItem('idUsuario');
  idPerfilAsignacion : string = parseInt(this.idPerfil) === 2 ||  parseInt(this.idPerfil) === 1 ? "3" : "5"; // Perfil al cual se asignara la encuesta.

  // Vars
  textTitulo : string = parseInt(this.idPerfil) === 2 || parseInt(this.idPerfil) === 1 ? 'Coordinadores' : 'Encuestadores';
  radioActivateButtons : boolean = parseInt(this.idPerfil) === 1 || parseInt(this.idPerfil) === 2 ? true : false;
  isOptionListButton : string = "1"
  isSelectedUser: any; // Usuario seleccionado en el select

  //Inicializo variables
  fmSerachSelectCoordinador = new FormControl('');
  opcionesSeleccionadas: any = {};

  listRegiones: Region[] = [];
  // sedes: Sede[] = [];
  listComunas: Comuna[] = [];
  listUsuarios: Usuario[] = [];
  listEncuestas: Encuesta[] = [];

  // Variables auxiliares inicializadas
  auxText: any = {
    labelComuna: "Comunas",
    labelRegion: "Regiones",
    labelSede: "Sedes",
    labelEncuestas: "Encuestas",
    labelNoContent: "No Hay Coincidencias"
  }

  idUserSelected: any = 0;
  myControl = new FormControl('');
  options: any[] = [];
  filteredOptions: Observable<User[]>;
  // //
  // userSelected: any;
  // regionesSelected: any;
  // //
  
  

  
  // //
  // disabledSelectRegion: any = new FormControl(false);
  // //
  
  // recargarTable : any = 0;
  
  


  
  // All selected
  @ViewChild('allSelectedRegion') private allSelectedRegion: MatOption;
  @ViewChild('allSelectedComuna') private allSelectedComuna: MatOption;
  @ViewChild('allSelectedEncuestas') private allSelectedEncuestas: MatOption;

  formEncuestas = this.fb.group({
    searchSelectCoordinador: ['', Validators.required],
    selectRegiones: [''],
    selectComunas: [''],
    multiSelectEncuestas: [''],
    fcRegion: new FormControl(false),
    fcComuna: new FormControl(false),
    fcEncuestas: new FormControl(false)
  });


  ngOnInit() {
    // console.log(this.idPerfil);
    this.fncUsersService(); // Carga los usuarios segun el perfil
    this.fncGetRegiones(); // Carga las regiones
    this.fncGetComunas(); // Carga las comunas
    this.fncGetEncuestas(); // Carga las encuestas
    // this.InicializarSelects();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
    this.loadData(0);
  }
  //tabla
  refresh() {
    this.loadData();
  }

  onChangeRadioButtons(value :any){
    // 1 Coordinador and 2 Encuestador
    this.isOptionListButton = value;
    this.textTitulo = value === 1 ? 'Coordinadores' : 'Encuestadores';
    const perfil: string = value === 1 ? '3' : '5';
    this.idPerfilAsignacion = perfil;
    this.limpiarSelect(true);
  }

  public loadData(idUser : any = 0) {
    console.log("Acá...")
    this.exampleDatabase = new AsignacionEncuestasService(this.httpClient);
    this.dataSource = new EncuestasAsignadasDataSource(
      this.exampleDatabase,
      this.paginator,
      this.sort,
      idUser,
      this.idPerfilAsignacion
    );
    // console.log(this.dataSource);
    if (this.dataSource.filteredData.length <= 0) {
      this.isTblLoading = false;
      return;
    }
    // this.subs.sink = fromEvent(this.searchSelectCoordinador)
    // this.isTblLoading = false;
    //this.dataSource.filter = this.filter.nativeElement.value;
    // this.subs.sink = fromEvent(this.filter.nativeElement, 'keyup')
    //   .subscribe(() => {
    //     if (!this.dataSource) {
    //       return;
    //     }
    //     this.isTblLoading = false;
    //     this.dataSource.filter = this.filter.nativeElement.value;
    //   });
  }

  onClickCoordinadorSelected(userSelected :any){
    const {name , id} : any = userSelected !== undefined ? this.isSelectedUser : 0;
    // console.log(`name: ${name} |||| id: ${id}`);
    this.idUserSelected = id;
    this.loadData(id);
  }

  saveOptionsSelected(variable: any, options: any) {
    this[variable] = options;
  }

  getSelectedData(allSelectName: string = "", listName: string, fcName: string, selectName: string) {
    let response: any = [];
    if (this[allSelectName].selected) {
      response = this[listName];
    } else {
      response = this[listName].filter(option => {
        if (this.formEncuestas.controls[selectName].value) {
          if (this.formEncuestas.controls[selectName].value.find(e => e == option.value)) {
            return option;
          }
        }
      });
    }
    return response;
  }

  fncConfirmarAsignacion(f: NgForm) {
    let dataAux: any = {};
    // console.log(this.isSelectedUser);
    if (this.isSelectedUser !== undefined) {
      // console.log(this.formEncuestas.controls.fcEncuestas.value);
      if (!this.formEncuestas.controls.fcEncuestas.value) {
        // Obtengo los valores del formulario
        let listEncuestasSelected : any = this.getSelectedData('allSelectedEncuestas', 'listEncuestas', 'fcEncuestas', 'multiSelectEncuestas');
        if (listEncuestasSelected.length === 0) {
          listEncuestasSelected = this.listEncuestas;
        }
        console.log('Encuestas seleccionadas ::', listEncuestasSelected);
        dataAux = {
          userAsignado: this.isSelectedUser,
          regiones: this.listRegiones,
          comunas: this.listComunas,
          encuestas: listEncuestasSelected,
          allEncuestas: this.listEncuestas
        }
        const dialogRef = this.matModalDialog.open(DialogContent, {
          data: dataAux
        });

        dialogRef.afterClosed().subscribe(result => {
          // console.log(`Dialog result: ${result}`);
          if (result) {
            this.asignarEncuesta(f)
          }
        });
      } else {
        this.generalUtils.Toast.fire({icon: 'error', title: 'No hay encuestas asignables', timer:2500});
      }
    } else {
      this.generalUtils.Toast.fire({icon: 'error',title: 'Debe seleccionar a quien asignar la(s) encuesta(s).', timer:2500});
    }
  }
  asignarEncuesta(f) {
    // console.log('f:: ', f);
    const usu_id_padre: number = parseInt(this.idUsuario);
    const usu_id: number = this.isSelectedUser.id;
    const idUserAsignar: any = this.idPerfilAsignacion;
    const regiones: any = this.formEncuestas.value.selectRegiones === '' ? this.listRegiones.map((region: any) => region.value) : this.formEncuestas.value.selectRegiones;
    const comunas: any = this.formEncuestas.value.selectComunas === '' ? this.listComunas.map((comuna: any) => comuna.value) : this.formEncuestas.value.selectComunas;
    const encuestas: any = this.formEncuestas.value.multiSelectEncuestas === undefined ? this.listEncuestas.map((encuesta: any) => encuesta.value) : this.formEncuestas.value.multiSelectEncuestas;

    const dataAux: any = {
      "usu_id_padre": usu_id_padre,
      "usu_id": usu_id,
      "tipo_perfil_asignar": idUserAsignar,
      "regiones": regiones,
      "comunas": comunas,
      "encuestas": encuestas
    };
    // console.log(dataAux);
    this.asignacionEncuestasService.addEncuestas(
      dataAux 
    ).subscribe(response => {
      // console.log(response);
      if(parseInt(response.status) === 200){
        this.generalUtils.Toast.fire({icon: 'success', title: 'Encuestas asignadas correctamente.', timer:2500});
        // this.limpiarSelect(false);
        this.limpiarSelect();
      }else{
        this.generalUtils.Toast.fire({ icon: 'error', title: 'Hubo un problema al asignar.', timer: 2500});
      }
    })
  }

  limpiarSelect(userClean: boolean = true){
    this.formEncuestas.controls.multiSelectEncuestas.patchValue([]);
    this.formEncuestas.controls.selectRegiones.patchValue([]);
    this.formEncuestas.controls.selectComunas.patchValue([]);
    this.listUsuarios = [];
    this.listRegiones = [];
    this.listComunas = [];
    this.listEncuestas = [];
    // this.InicializarSelects();
    // console.log('userClean : ', userClean);
    this.fncUsersService();
    this.fncGetRegiones();
    this.fncGetComunas();
    this.fncGetEncuestas();
    this.loadData(userClean ? 0 : this.idUserSelected);
  }

  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }


  tosslePerOne(all: any, fcSelectName: string, allSelected: string, listName: string, selectName: string) {
    if (this[allSelected].selected) {
      this[allSelected].deselect();
      return false;
    }
    // console.log("values" + listName.charAt(0).toUpperCase() + listName.slice(1))
    this.opcionesSeleccionadas["values" + listName.charAt(0).toUpperCase() + listName.slice(1)] = this.formEncuestas.controls[fcSelectName].parent.value[selectName]
    if (this.formEncuestas.controls[fcSelectName].parent.value[selectName].length == this[listName].length) {
      this[allSelected].select();
    }
    if ( selectName === 'selectRegiones' && this.idPerfilAsignacion === '5' ) {
      this.regionesSeleccionadas = this.formEncuestas.controls.selectRegiones.value;
      this.fncUsersService(); // Carga los usuarios segun el perfil
    }
    // console.log(this.opcionesSeleccionadas);
    this.onChangeFiltros();
  }

  toggleAllSelection(selectName: string, listName: string, allSelected: string) {
    // console.log(`SelectName: ${selectName} || ListName: ${listName} || AllSelected: ${allSelected}`);
    if (this[allSelected].selected) {
      this.formEncuestas.controls[selectName]
        .patchValue([...this[listName].map(item => {
          return item.value
        }), 0]);
    } else {
     
      this.formEncuestas.controls[selectName].patchValue([]);
    }
    this.opcionesSeleccionadas["values" + listName.charAt(0).toUpperCase() + listName.slice(1)] = this.formEncuestas.controls[selectName].value;
    if ( selectName === 'selectRegiones' && this.idPerfilAsignacion === '5' ) {
      this.regionesSeleccionadas = this.formEncuestas.controls.selectRegiones.value;
      this.fncUsersService(); // Carga los usuarios segun el perfil
    }
    this.onChangeFiltros();
  }

  onChangeFiltros(options: any = []) {
    // console.log('Se activo onChange');
    // console.log(this.opcionesSeleccionadas);
    const idUsuario: any = this.idUsuario;
    const idRegiones: any = this.opcionesSeleccionadas.valuesListRegiones ? this.opcionesSeleccionadas.valuesListRegiones.filter(e => e !== 0) : [];
    const idComunas: any = this.opcionesSeleccionadas.valuesListComunas ? this.opcionesSeleccionadas.valuesListComunas.filter(e => e !== 0) : [];
    // console.log(' - ', idUsuario);
    // console.log(' - ', idRegiones);
    // console.log(' - ', idComunas);
    this.asignacionEncuestasService.getComunas(idRegiones, '').subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        this.listComunas = [];
        data.map((comuna: any) => this.listComunas.push({ "label": comuna.label, "value": comuna.value }));
        if (this.listComunas.length > 0) {
          // this.formEncuestas.controls.fcSede.patchValue(false);
          this.auxText.labelComuna = "Comunas";
        } else {
          // this.formEncuestas.controls.fcSede.patchValue(true);
          this.auxText.labelComuna = this.auxText.labelNoContent;
        }
      } else {
        console.error('FiltradoContinuo() => api getComunas aux MSG:: ' + msg);
      }
    });

    this.asignacionEncuestasService.getRegiones(idRegiones, idComunas).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        this.listRegiones = [];
        data.map((region: any) => this.listRegiones.push({ "label": region.label, "value": region.value }));
        if (this.listRegiones.length > 0) {
          this.formEncuestas.controls.fcRegion.patchValue(false);
          this.auxText.labelRegion = "Regiones";
        } else {
          this.formEncuestas.controls.fcRegion.patchValue(true);
          this.auxText.labelRegion = this.auxText.labelNoContent;
        }
      } else {
        console.error(msg);
      }
    });
    
    this.asignacionEncuestasService.getEncuestas(idUsuario, idRegiones, idComunas, this.idPerfilAsignacion).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        this.listEncuestas = [];
        data.map((encuesta: any) => this.listEncuestas.push({
          "label": encuesta.id_origen_label,
          "value": encuesta.id_encuesta,
          "idComuna": encuesta.id_comuna,
          "idRegion": encuesta.id_region,
          "direccion" : encuesta.direccion
        }));
        if (this.listEncuestas.length > 0) {
          this.auxText.labelEncuestas = "Encuestas";
          this.formEncuestas.controls.fcEncuestas.patchValue(false);
        } else {
          this.formEncuestas.controls.fcEncuestas.patchValue(true);
          this.auxText.labelEncuestas = this.auxText.labelNoContent;
          this.formEncuestas.controls.multiSelectEncuestas.patchValue([]);
        }
      } else {
        console.error(msg);
      }
    });

  }

  fncUsersService( idPerfil : string = this.idPerfilAsignacion ) {
    this.listUsuarios = [];
    this.usuarioService.getUsersPerfil(this.idUsuario, idPerfil, this.regionesSeleccionadas).subscribe(
      ({ data, msg, status }) => {
        if (parseInt(status) === 200) {
          // console.log(data);
          data.map((user: any) => {
            return this.listUsuarios.push({ "name": user.nombre_user, "id": user.id_user });
          });
        } else {
          console.error(msg);
        }
      }
    );
  }

  fncGetRegiones() {
    this.listRegiones = [];
    this.asignacionEncuestasService.getRegiones().subscribe(({ data, msg, status }) => {
      // console.log(data);
      if (parseInt(status) === 200) {
        data.map((region: any) => {
          return this.listRegiones.push({ "label": region.label, "value": region.value })
        });
      } else {
        console.error(msg);
      }
    });
  }

  fncGetComunas() {
    this.asignacionEncuestasService.getComunas().subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        data.map((comuna: any) => this.listComunas.push({ "label": comuna.label, "value": comuna.value }));
      } else {
        console.error(msg);
      }
    });
  }

  fncGetEncuestas() {
    const idUsuario: any = this.idUsuario;
    this.asignacionEncuestasService.getEncuestas(idUsuario, [], [], this.idPerfilAsignacion).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        data.map((encuesta: any) => this.listEncuestas.push({
          "label": encuesta.id_origen_label,
          "value": encuesta.id_encuesta,
          "idComuna": encuesta.id_comuna,
          "idRegion": encuesta.id_region,
          "direccion" : encuesta.direccion
        }));
      } else {
        console.error(msg);
      }
    });
  }

  deleteEncuesta(pKey: any) {
    console.log('idEncuesta : ', pKey);
    Swal.fire({
      title: "¿Esta seguro que desea eliminar la encuesta?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Si",
      denyButtonText: `Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.asignacionEncuestasService.deleteEncuesta(pKey).subscribe(({ data, msg, status }) => {
          if (parseInt(status) === 200) {
            this.generalUtils.Toast.fire({icon: 'success', title: 'Encuesta eliminada correctamente.', timer:2500});
            this.loadData(this.idUserSelected);
          } else {
            this.generalUtils.Toast.fire({icon: 'error', title: 'Hubo un problema al eliminar.', timer:2500});
          }
        });
        // this.generalUtils.Toast.fire({icon: 'success', title: 'Encuesta eliminada correctamente.', timer:2500});
      }
    });
    
  
  }
}

export class EncuestasAsignadasDataSource extends DataSource<EncuestaModel> {
  filterChange = new BehaviorSubject('');
  // get filter(): string {
  //   return this.filterChange.value;
  // }
  // set filter(filter: string) {
  //   this.filterChange.next(filter);
  // }
  filteredData: any[] = [];
  renderedData: EncuestaModel[] = [];
  constructor(
    //   public exampleDatabase: CafService,
    private _ws_ac: AsignacionEncuestasService,
    public paginator: MatPaginator,
    public _sort: MatSort,
    public idUsuario : string,
    public idPerfilAsignacion : string
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<EncuestaModel[]> {
    // console.log('OPA?')
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this._ws_ac.dataChange,
      // this._sort.sortChange,
      this.filterChange,
      this.paginator.page
    ];
    // this.exampleDatabase.getAllContactss();
    this._ws_ac.getUserEncuestas(parseInt(this.idUsuario), this.idPerfilAsignacion);
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._ws_ac.data
          .slice()
          .filter((resEncuesta: any) => {
            const searchStr = (
              resEncuesta.color_dias
              + resEncuesta.color_estado
              + resEncuesta.dias_diferencia.toString()
              + resEncuesta.dias_restantes
              + resEncuesta.estado
              + resEncuesta.fecha_asignacion
              + resEncuesta.fecha_encuesta_origen
              + resEncuesta.fecha_tope
              + resEncuesta.id_origen.toString()
              + resEncuesta.id_origen_label
              + resEncuesta.direccion
              + resEncuesta.id_comuna.toString()
              + resEncuesta.nombre_comuna
              + resEncuesta.id_encuesta.toString()
              + resEncuesta.id_estado.toString()
              + resEncuesta.id_region.toString()
              + resEncuesta.nombre_region
              + resEncuesta.id_user.toString()
              + resEncuesta.msg_encuesta
              + resEncuesta.numero.toString()
            ).toLowerCase();
            return searchStr;
          });
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());
        console.log('sortedData : ', sortedData);
        // sortedData.map((encuesta :EncuestaModel) => {
        //   encuesta.fecha_tope = encuesta.fecha_tope.slice(0, 10);
        //   encuesta.fecha_encuesta_origen = encuesta.fecha_encuesta_origen.slice(0,10);
        // });
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this.paginator.pageSize
        );
        return this.renderedData;
      })
    );
  }
  disconnect() { }
  /** Returns a sorted copy of the database data. */
  sortData(data: EncuestaModel[]): EncuestaModel[] {
    return data;
  }
}


