import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { Subject, BehaviorSubject, Observable } from 'rxjs';
// import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TypesServices {

    constructor(private http: HttpClient) { }

    //Api para obtener las regiones que tengan encuestas asignadas o todas
    getRegionesUser(idUser: number, idComuna: number) {
        const params = { "id_user": idUser, "id_comuna": idComuna };
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/types/regiones-user`, params)
            .pipe(map(data => data));
    }

    getComunasUser(idUser: number, idRegion: number) {
        const params = { "id_user": idUser, "id_region": idRegion};
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/types/comunas-user`, params)
            .pipe(map(data => data));
    }

    // getIncidentes() {
    //     return this.http.post<any>(`${environment.apiUrl}/api/get-incidencias`, {}).pipe(map(data => data));
    // }

}