<mat-card>
  <mat-card-title>
    <mat-icon class="material-icons-outlined">arrow_forward</mat-icon>
    Encuestas Asignadas
  </mat-card-title>
  <mat-divider></mat-divider>

  <div *ngIf="idPerfil!=5" class="mt-4">
    <div class="row mt-4">
      <!-- <div class="col-lg-6 col-sm-12">
        <mat-card-title> 
          <mat-icon class="material-icons-outlined">person</mat-icon>
          {{ nombreEncuestador }}
        </mat-card-title>
      </div> -->
      <div class="col-lg-6 col-sm-12">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Encuestadores</mat-label>
          <input type="text" placeholder="Nombre encuestador" aria-label="Encuestadores" matInput
            [formControl]="myControl" [matAutocomplete]="auto" oninput="this.value = this.value.toUpperCase()" (keyup.enter)="getEncuestasEncuestador('enter', {})">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name"
              (click)="getEncuestasEncuestador('click', option)">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-card-content style="margin-top: 20px;">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="Numero">
        <th mat-header-cell *matHeaderCellDef>FOLIO</th>
        <td mat-cell *matCellDef="let element" width="5%" align="center"> {{element.id_origen}} </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="ID">
        <th mat-header-cell *matHeaderCellDef>DIRECCIÓN</th>
        <td mat-cell *matCellDef="let element" align="center"> {{element.id_origen_label}} </td>
      </ng-container>

      <!-- Name Column -->
      <!-- Weight Column -->
      <!-- <ng-container matColumnDef="Region">
        <th mat-header-cell *matHeaderCellDef>REGIÓN</th>
        <td mat-cell *matCellDef="let element" align="center"> {{element.nombre_region}} </td>
      </ng-container> -->

      <!-- Symbol Column -->
      <!-- <ng-container matColumnDef="Comuna">
        <th mat-header-cell *matHeaderCellDef>COMUNA</th>
        <td mat-cell *matCellDef="let element" width="10%" align="center"> {{element.nombre_comuna}} </td>
      </ng-container>
  
      <ng-container matColumnDef="Sede">
        <th mat-header-cell *matHeaderCellDef>SEDE</th>
        <td mat-cell *matCellDef="let element" width="10%" align="center"> {{element.nombre_sede}} </td>
      </ng-container>-->

      <ng-container matColumnDef="Fecha">
        <th mat-header-cell *matHeaderCellDef>FECHA ENCUESTA</th>
        <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.fecha_encuesta_origen}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="FechaTope">
        <th mat-header-cell *matHeaderCellDef>FECHA TOPE</th>
        <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.fecha_tope}} </td>
      </ng-container> -->

      <ng-container matColumnDef="FechaAsignacion">
        <th mat-header-cell *matHeaderCellDef>FECHA ASIGNACIÓN</th>
        <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.fecha_asignacion}} </td>
      </ng-container>

      <ng-container matColumnDef="Dias-Restantes">
        <th mat-header-cell *matHeaderCellDef>DÍAS RESTANTES</th>
        <td mat-cell *matCellDef="let element" width="15%" align="center">
          <h6><span [class]="getColorDias(element.color_dias)">{{element.dias_restantes}}</span></h6>
        </td>
      </ng-container>

      <ng-container matColumnDef="Estado">
        <th mat-header-cell *matHeaderCellDef>ESTADO</th>
        <td mat-cell *matCellDef="let element" width="15%" align="center" [class]="getColor(element.color_estado)">
          <b>{{element.estado}}</b>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="Ubicacion">
        <th mat-header-cell *matHeaderCellDef>UBICACIÓN</th>
        <td mat-cell *matCellDef="let element" width="20%" align="center">
          {{element.direccion}}
        </td>
        <td mat-cell *matCellDef="let element" width="20%" align="center"> <a
            href="https://www.google.com/maps/place/{{element.coordenadas_google_maps}}"
            target="_blank">Abrir en Google Maps</a> </td>
      </ng-container> -->

      <ng-container matColumnDef="Detalle">
        <th mat-header-cell *matHeaderCellDef> DETALLE </th>
        <td mat-cell *matCellDef="let element" align="center">
          <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
            (click)="detailsCall(element)">
            <mat-icon>search</mat-icon>
          </button>
          <!-- <button type="button" class="btn btnHistorial" (click)="getDetalle()">
            <span class="badge col-blue shadow-style">Ver Detalle</span>
          </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow align="center">
        <td class="mat-cell" colspan="9">
          <div *ngIf="isTblLoading; else elseLoading" class="mt-4 mb-4">
            <mat-progress-spinner color="primary" [diameter]="40" mode="indeterminate">
            </mat-progress-spinner>
          </div>
          <ng-template #elseLoading>{{noData}}</ng-template>
        </td>
      </tr>
    </table>
    <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </mat-card-content>
</mat-card>
<!-- <div>
<mat-grid-list cols="2" rowHeight="2:1">
  <mat-card [class.mat-elevation-z8]="true" class="card-google-map" style="margin-top: 30px;">
    <mat-card-title>
      Ubicacion en el mapa
    </mat-card-title>
    <mat-card-subtitle>
      Google Maps
    </mat-card-subtitle>
    <mat-card-content>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13306.539584187714!2d-70.5859751!3d-33.510875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2scl!4v1671568057089!5m2!1ses-419!2scl"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </mat-card-content>
  </mat-card>
</mat-grid-list>
</div> -->