import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MostrarEncuestas } from 'src/app/core/service/mostrar-encuentas.service';
import { AsignacionEncuestasService } from 'src/app/core/service/asignacion-encuestas.service';
import { FormComponent } from './form/form.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Encuesta } from './table-encuentas.model';
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { UnsubscribeOnDestroyAdapter } from 'src/app/components/shared/UnsubscribeOnDestroyAdapter';

export interface Encuestador {
  key: number;
  name: string;
}

@Component({
  selector: 'app-table-encuestas',
  templateUrl: './table-encuestas.component.html',
  styleUrls: ['./table-encuestas.component.scss']
})
// export class TableEncuestasComponent implements OnInit, AfterViewInit {
export class TableEncuestasComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  public isTblLoading = true;
  noData: string = 'Sin información';
  modalObjetDetalle: object;
  nombreEncuestador: string = 'Encuestador';
  exampleDatabase: MostrarEncuestas | null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;

  // displayedColumns: string[] = ['Numero', 'Nombre', 'Region', 'Comuna', 'Sede', 'Fecha','Dias-Restantes','Estado', 'Detalle']; 
  displayedColumns: string[] = ['Numero', 'ID', 'Fecha','FechaAsignacion', 'Dias-Restantes', 'Estado','Detalle'];
  encuestas = []

  dataSource: TablaEncuentaDataSource | null;



  // Autocomplete
  myControl = new FormControl('');
  options: Encuestador[] = [];
  filteredOptions: Observable<Encuestador[]>;

  constructor(public httpClient: HttpClient,
    public dialog: MatDialog
    , private mostrarEncuestasService: MostrarEncuestas
    , private asignacionEncuestasService: AsignacionEncuestasService) {
    super();
  }
  idPerfil: number = parseInt(localStorage.getItem('idPerfil'));
  idUsuarioLocal: string = localStorage.getItem('idUsuario');
  idUsuario: string = localStorage.getItem('idUsuario');
  // console.log() -- 1	Administrador -- 2	Coordinador Cadem -- 3	Coordinador Regional -- 4	Cliente -- 5	Encuestador

  ngAfterViewInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        let returnFilter = []
        if (typeof value === 'number') {
          returnFilter = this._filterKey(value || 0);
        } else {
          returnFilter = this._filter(value || '');
        }
        return returnFilter;
      }),
    );

    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    //console.log(this._ac)
    console.log('TIPO PERFIL :: ', this.idPerfil)
    if (this.idPerfil === 5) {
      this.loadData(this.idUsuarioLocal);
      // this.getServicesEncuentasUser(this.idUsuario);
    } else {
      this.loadData("0");
      this.getEncuestadores(); // Obtener todos los encuestadores.
    }
    this.refreshTable()
  }
  refresh() {
    this.loadData();
  }
  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  private _filter(value: any): Encuestador[] {
    const filterValue = value.toLowerCase();
    const filterData = this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    return filterData;
  }
  private _filterKey(value: any): Encuestador[] {
    const filterValue = value;
    const filterData = this.options.filter(option => option.key === filterValue);
    return filterData;
  }

  displayFn(user: Encuestador): string {
    return user && user.name ? user.name : '';
  }

  getEncuestadores() {
    this.asignacionEncuestasService.getUsers(this.idUsuarioLocal, '5').subscribe(
      ({ data, msg, status }) => {
        if (parseInt(status) === 200) {
          // console.log("getEncuestadores")
          // console.log(data)
          data.map((user: any) => {
            return this.options.push({ key: user.id_user, name: user.nombre_user.toUpperCase() });
          });
        } else {
          console.error(msg);
        }
      });
  }
  // private _filter(name: string): Encuestador[] {
  //   const filterValue = name.toLowerCase();
  //   return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  // }
  public loadData( idUsuario : string = "0" ) {
    // console.log("Acá...");
    this.exampleDatabase = new MostrarEncuestas(this.httpClient);
    this.idUsuario = idUsuario
    this.dataSource = new TablaEncuentaDataSource(
      this.exampleDatabase,
      this.paginator,
      this.sort,
      this.idUsuario
    );
    if ( this.filter?.nativeElement ){
      this.subs.sink = fromEvent(this.filter?.nativeElement, 'keyup')
        // .debounceTime(150)
        // .distinctUntilChanged()
        .subscribe(() => {
          if (!this.dataSource) {
            // this.isTblLoading = false;
            return;
          }
          this.isTblLoading = false;
          this.dataSource.filter = this.filter.nativeElement.value;
        }
      );
    } else { this.isTblLoading = false; }
  }

  getDetalleProducto(tipo: number, value: string) {

    // if ( this.setArrayProductos.length > 0 ) {
    //   const [result] = this.setArrayProductos.filter(producto => producto.codigo === value);
    //   const productos = this.frmEnvioDTE.get('setProductos') as FormArray
    //   productos.controls[idFormGroup].patchValue({
    //     txtProductoNombre: result.nombre,
    //     txtProductoCantidad: result.cantidad,
    //     txtProductoPrecio: result.precio,
    //     txtProductoDescuento: result.descuento,
    //   });
    //   this.setDetalleProductoOperacion(idFormGroup);
    // }
  }

  // Function
  // getServicesEncuestasUser(pIdUser : string = "") {

  //   // console.log(localStorage.getItem('idUsuario'))
  //   // if (pIdUser)
  //   this.mostrarEncuestasService.getEncuestas(pIdUser).then(e=> {
  //     console.log(e.data)
  //     this.dataSource= new MatTableDataSource<any>(e.data);
  //     this.encuestas=e.data

  //     this.isTblLoading = false;
  //     console.log(this.dataSource);
  //   })
  // } 
  getServicesEncuentasUser(pIdUser: string = "") {
    this.mostrarEncuestasService.getEncuestas(pIdUser)
  }

  filtrarEncuestasEncuestador() {
    console.log("jahajhsj")
  }

  getColor(estado: string) {
    // console.log(estado);
    let color = ''
    switch (estado) {
      case 'success':
        color = 'color-text-success';
        break;
      case 'warning':
        color = 'color-text-warn';
        break;
      case 'error':
        color = 'color-text-error';
        break;
      case 'primary':
        color = 'color-text-primary'
        break;
    }
    return color;
  }

  getColorDias(estado: string) {
    // console.log(estado);
    let color = ''
    switch (estado) {
      case 'success':
        color = 'green-bubble';
        break;
      case 'warning':
        color = 'warning-bubble';
        break;
      case 'error':
        color = 'red-bubble';
        break;
      case 'primary':
        color = 'primary-bubble'
        break;
      default:
        color = 'grey-bubble'
        break;
    }
    return color;
  }

  getEncuestasEncuestador(type : string, data: any) {
    if (type === 'click') {
      console.log('Click: ', data.key);
      this.loadData(data.key);
    } else {
      const {key, name} = this.options.find(option => option.name.toUpperCase() === this.myControl.value);
      this.loadData(`${key}`);
    }
  }

  // onEnter() {
  //   const inputValue = this.myControl.value;
  //   console.log
  //   console.log('Enter key pressed with input value:', inputValue);
  //   // Aquí puedes poner la lógica que necesites ejecutar al presionar Enter
  // }

  detailsCall(row) {
    // console.log(row)
    let tempDirection;
    if (localStorage.getItem('isRtl') === 'true') {
      tempDirection = 'rtl';
    } else {
      tempDirection = 'ltr';
    }
    this.dialog.open(FormComponent, {
      data: {
        detail: row,
        action: 'details'
      },
      direction: tempDirection,
      // height: '70%',
      width: '50%'
    });
  }
  // fncToUpperCase( value : any ) {

  //   console.log(value);

  // }

}

export class TablaEncuentaDataSource extends DataSource<Encuesta> {
  filterChange = new BehaviorSubject('');
  get filter(): string {
    return this.filterChange.value;
  }
  set filter(filter: string) {
    this.filterChange.next(filter);
  }
  filteredData: any[] = [];
  renderedData: any[] = [];
  constructor(
    public mostrarEncuestasService: MostrarEncuestas,
    public paginator: MatPaginator,
    public _sort: MatSort,
    public idUsuario : string
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Encuesta[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.mostrarEncuestasService.dataChange,
      // this._sort.sortChange,
      this.filterChange,
      this.paginator.page
    ];

    this.mostrarEncuestasService.getEncuestas( this.idUsuario );
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this.mostrarEncuestasService.data
          .slice()
          .filter((resEncuesta: any) => {
            const searchStr = (
              resEncuesta.color_dias +
              resEncuesta.color_estado +
              resEncuesta.dias_diferencia +
              resEncuesta.dias_restantes +
              resEncuesta.estado +
              resEncuesta.fecha_asignacion +
              resEncuesta.fecha_encuesta_origen +
              resEncuesta.fecha_tope +
              resEncuesta.id_cadem +
              resEncuesta.id_origen_label +
              resEncuesta.id_comuna +
              resEncuesta.nombre_comuna +
              resEncuesta.id_encuesta +
              resEncuesta.id_estado +
              resEncuesta.id_region +
              resEncuesta.nombre_region +
              resEncuesta.id_user +
              resEncuesta.msg_encuesta +
              resEncuesta.numero +
              resEncuesta.coordenadas_google_maps +
              resEncuesta.observacion_ine
            ).toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
          });
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());

        // sortedData.map((encuesta :Encuesta) => {
        //   encuesta.fecha_tope = encuesta.fecha_tope.slice(0, 10);
        //   //encuesta.fecha_encuesta_casen = encuesta.fecha_encuesta_casen.slice(0,10);
        // });
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this.paginator.pageSize
        );
        return this.renderedData;
      })
    );
  }
  disconnect() { }
  /** Returns a sorted copy of the database data. */
  sortData(data: Encuesta[]): Encuesta[] {
    // return [];
    return data;
    // if (!this._sort.active || this._sort.direction === '') {
    // }
    // return data.sort((a, b) => {
    //   let propertyA: number | string = '';
    //   let propertyB: number | string = '';
    //   switch (this._sort.active) {
    //     case 'id':
    //       [propertyA, propertyB] = [a.dte_nombre, b.dte_nombre];
    //       break;
    //     // case 'name':
    //     //   [propertyA, propertyB] = [a.name, b.name];
    //     //   break;
    //     // case 'email':
    //     //   [propertyA, propertyB] = [a.email, b.email];
    //     //   break;
    //     // case 'birthDate':
    //     //   [propertyA, propertyB] = [a.birthDate, b.birthDate];
    //     //   break;
    //     // case 'address':
    //     //   [propertyA, propertyB] = [a.address, b.address];
    //     //   break;
    //   }
    //   const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
    //   const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
    //   return (
    //     (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1)
    //   );
    // });
  }
}