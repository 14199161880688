import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    isLoading$ = new Subject<boolean>();

    private isLoggedInSubject: BehaviorSubject<string>;
    public isLoggedInOb: Observable<string>;

    constructor(private http: HttpClient) {
        this.isLoggedInSubject = new BehaviorSubject<string>(
            JSON.parse(localStorage.getItem('isLoggedin'))
        );
        this.isLoggedInOb = this.isLoggedInSubject.asObservable();
    }

    public get currentUserValue(): string {
        //console.log(this.currentUserSubject.value)
        return this.isLoggedInSubject.value;
    }

    userLogIn(username: string, password: string) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/usuario/login`, {
                "username": username,
                "password": password
            })
            .pipe(
                map((data) => {
                    this.isLoggedInSubject.next(data.user);
                    return data
                })
            );
    }

    getToken(){
        return localStorage.getItem('token')
    }

    show(): void {
        this.isLoading$.next(true);
    }

    hide(): void {
        this.isLoading$.next(false);
    }

}