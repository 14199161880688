import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { Subject, BehaviorSubject, Observable } from 'rxjs';
// import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class IndicadoresServices {

    constructor(private http: HttpClient) { }

    getIndicadoresEncuestador(idUser : string, valTipo : number, idRegion : number = 0, idComuna : number = 0) {
        const params = { "id_user": idUser
                        , "ind_tipo": valTipo
                        , "id_region": idRegion
                        , "id_comuna": idComuna }
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/indicadores/get-encuestador`, params)
            .pipe(map(data => data));
    }

    getIndicadoresCatiRegion(idUser : string, idRegion : number = 0, idComuna : number = 0) {
        const params = { "id_user": idUser
                        , "id_region": idRegion
                        , "id_comuna": idComuna }
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/indicadores/get-cati-region`, params)
            .pipe(map(data => data));
    }

    downloadIndicadoresEncuestador(idUser : string, valTipo : number, idRegion : number = 0, idComuna : number = 0) {
        const params = { "id_user": idUser
                        , "ind_tipo": valTipo
                        , "id_region": idRegion
                        , "id_comuna": idComuna }
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/indicadores/get-download-encuestador`, params)
            .pipe(map(data => data));
    }

    getIndicadoresTrabajoCampo(idUser : string, valTipo : number, idRegion : number = 0, pMetodologia : string = "1") {
        const params = { "id_user": idUser
                        , "ind_tipo": valTipo
                        , "id_region": idRegion
                        , "metodologia" : pMetodologia }
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/indicadores/get-trabajo-campo`, params)
            .pipe(map(data => data));
    }

    downloadIndicadoresTrabajoCampo(idUser : string, valTipo : number, idRegion : number = 0, pMetodologia : string = "1") {
        const params = { "id_user": idUser
                        , "ind_tipo": valTipo
                        , "id_region": idRegion 
                        , "metodologia" : pMetodologia }
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/indicadores/get-download-trabajo-campo`, params)
            .pipe(map(data => data));
    }

    getIndicadoresSupervision(idUser : string, valTipo : number, idRegion : number = 0, tipoMetdologia : number = 0) {
        const params = { "id_user": idUser
                        , "ind_tipo": valTipo
                        , "id_region": idRegion
                        , "tipo_metodologia": tipoMetdologia}
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/indicadores/get-supervision`, params)
            .pipe(map(data => data));
    }

    downloadSupervision(idUser : string, valTipo : number, idRegion : number = 0, tipoMetdologia : number = 0) {
        const params = { "id_user": idUser
                        , "ind_tipo": valTipo
                        , "id_region": idRegion 
                        , "tipo_metodologia": tipoMetdologia}
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/indicadores/get-download-supervision`, params)
            .pipe(map(data => data));
    }

    downloadIndicadoresCatiRegion(idUser : string, idRegion : number = 0, idComuna : number = 0) {
        const params = { "id_user": idUser
                        , "id_region": idRegion
                        , "id_comuna": idComuna }
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/indicadores/get-download-cati-region`, params)
            .pipe(map(data => data));
    }
       
}