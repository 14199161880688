<div class="addContainer">
  <div *ngIf="isDetails" class="contact-details">
    <!-- <div class="modalHeader">
      <div class="editRowModal">
        <div class="contact-details-heading">
          <div class="contact-details-name mb-2">{{ detail.dte_nombre}}</div>
          <div *ngIf="  detail.estado === 'Activo'; else elseBlock">
            <div class="badge col-green">{{ detail.estado}}</div>
          </div>
          <ng-template #elseBlock>
            <div class="badge col-purple">{{  detail.estado}}</div>
          </ng-template>
        </div>
      </div>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div> -->
    <div mat-dialog-content>
      <div class="body table-responsive">
        <table class="table" width="100%">
          <thead>
            <tr>
              <th colspan="4">
                <!-- <mat-card-title> -->
                  <!-- <span class="material-icons-outlined">info</span> -->
                  INFORMACIÓN
                <!-- </mat-card-title> -->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th width="20%" class="mat-header-cell">FOLIO:</th>
              <td width="30%">{{detail.id_origen}}</td>
              <th width="20%" class="mat-header-cell">ESTADO: </th>
              <td width="30%" [class]="getColor(detail.color_estado)">
                <b>{{detail.estado}}</b> 
              </td>
            </tr>
            <tr>
              <th width="20%" class="mat-header-cell">FECHA ENCUESTA: </th>
              <td width="30%">{{detail.fecha_encuesta_origen}}</td>
              <th width="20%" class="mat-header-cell">FECHA ASIGNACIÓN:</th>
              <td width="30%">{{detail.fecha_asignacion}}</td>
            </tr>
            <!---->
            <tr>
              <th width="20%" class="mat-header-cell">REGIÓN:</th>
              <td width="80%" colspan="3">{{detail.nombre_region}}</td>
            </tr>
            <!-- <tr>
              <th width="20%" class="mat-header-cell">SEDE:</th>
              <td width="80%" colspan="3">{{detail.id_casen}}</td>
            </tr> -->
            <tr>
              <th width="20%" class="mat-header-cell">COMUNA:</th>
              <td width="80%" colspan="3">{{detail.nombre_comuna}}</td>
            </tr>
            <tr>
              <th width="20%" class="mat-header-cell">DIRECCIÓN:</th>
              <td width="80%" colspan="3">{{detail.direccion}}</td>
            </tr>
            <tr>
              <th width="20%" class="mat-header-cell">CÓDIGO INCIDENCIA:</th>
              <td width="80%" colspan="3" style="text-align: justify;">{{detail.codigo_incidencia ? detail.codigo_incidencia : '-'}}</td>
            </tr>
            <tr>
              <th width="20%" class="mat-header-cell">MENSAJE INCIDENCIA:</th>
              <td width="80%" colspan="3" style="text-align: justify;">{{detail.texto_incidencia ? detail.texto_incidencia : '-'}}</td>
            </tr>
            <!-- <tr>
              <th width="20%" class="mat-header-cell">OBESERVACIÓN:</th>
              <td width="80%" colspan="3" rowspan="3" style="text-align: justify;">{{detail.observacion_ine}}</td>
            </tr> -->
            <!-- <tr>
              <th width="20%" class="mat-header-cell">ESTADO: </th>
              <td width="30%" [class]="getColor(detail.color_estado)">
                <b>{{detail.estado}}</b> 
              </td>
            </tr>
            <tr>
              <th width="20%" class="mat-header-cell">INFORMACIÓN:</th>
              <td width="80%" colspan="3">{{detail.txt_fecha_caf}}</td>
            </tr> -->
          </tbody>
          <!--<thead>
            <tr>
              <th colspan="4">FOLIOS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th width="20%" class="mat-header-cell">RANGO FOLIOS:</th>
              <td width="30%">{{detailCaf.folio_inicio}} - {{detailCaf.folio_termino}}</td>
              <th width="20%" class="mat-header-cell">DISPONIBLES:</th>
              <td width="30%">{{detailCaf.vigentes}}</td>
            </tr>
            <tr>
              <th width="20%" class="mat-header-cell">UTILIZADOS:</th>
              <td width="30%">{{detailCaf.finalizadas}}</td>
              <th width="20%" class="mat-header-cell">ANULADOS O EXPIRADOS:</th>
              <td width="30%">{{detailCaf.expirado_o_anulado}}</td>
            </tr>
          </tbody>           -->
        </table>  
      </div>
    </div>
  </div>
</div>
