import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TableEncuestasCoordinadoresComponent } from './table-encuesta-coordinadores/table-encuestas.component';


const routes: Routes = [
  {
    path: '',
    component: TableEncuestasCoordinadoresComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EncuestasCoordinadorRoutingModule { }