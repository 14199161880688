<mat-card>
    <mat-card-title style="font-size: 32px; font-family: Noto Sans, sans-serif !important;">
      <mat-icon class="customIconSize material-icons-outlined">arrow_forward</mat-icon>
      Base de DFC
    </mat-card-title>
    <!-- <mat-divider></mat-divider> -->
    <div class="row">
      <div class="col-lg-12">
        <div style="text-align: center; padding: 40px 0 80px 0;">
          <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="getDFC()">
            <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
            Descargar
          </button> 
        </div>
      </div>
    </div>
  </mat-card>