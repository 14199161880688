import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DataEncuesta {
    id : string;
    name: string;
    region: string;
    comuna: string;
}


@Component({
    selector: 'app-acept-dialog',
    templateUrl: 'modal-acept-dialog.html',
    styleUrls: ['./confirm.component.scss']
})
export class DialogContent implements OnInit {
    displayedColumns: string[] = ['name', 'region', 'comuna', 'direccion'];
    dataSource:any;

    user:string;
    encuestas : DataEncuesta;

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.dataSource = data;
    }

    ngOnInit(): void {
        this.user = this.dataSource.userAsignado.name;
        this.encuestas = this.dataSource.encuestas.map(encuesta => {
            const regionAux:any = this.dataSource.regiones.find(element => parseInt(element.value) === parseInt(encuesta.idRegion));
            const comunaAux:any = this.dataSource.comunas.find(element => parseInt(element.value) === parseInt(encuesta.idComuna));
            return ({
                id: encuesta.value, 
                name: encuesta.label,
                region: regionAux.label,
                comuna: comunaAux.label,
                direccion: encuesta.direccion
            })
        });
    }
}