import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, Router
} from '@angular/router';
import Swal from 'sweetalert2';
import { DashboardService } from './service/dashboard.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  // constructor(
  //   private dashboardService: DashboardService,
  //   private readonly router: Router
  // ) { }
  constructor(private dashboardService: DashboardService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const token = this.dashboardService.getToken();
      if (token) {
          const tokenDecode: any = jwt_decode(token);
          const fechaActualUnix = Date.now();
          if((fechaActualUnix / 1000) > tokenDecode.exp){
              localStorage.setItem('isLoggedin', "false");
              localStorage.removeItem('idPerfil');
              localStorage.removeItem('token');
              localStorage.removeItem('idUsuario');
              Swal.fire({
                  icon: 'warning',
                  title: 'Oops... :c',
                  text: 'Sesión caducada. Ingrese de nuevo.'
              })
              this.router.navigate(['/login']);
              return false;
          }else{
              console.log(`Estoy logueado: ${Math.round(tokenDecode.exp - (fechaActualUnix / 1000))}`);
          }
          return true;
      }
      this.router.navigate(['/login']);
      return false;
  }
}

//   canActivate(
//     route: ActivatedRouteSnapshot, 
//     state: RouterStateSnapshot
//   ): Observable<boolean> | Promise<boolean> | boolean {
//     // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     // const token = this.authServiceService.getToken();
//     // if (token) {
//     //     const tokenDecode: any = jwt_decode(token);
//     //     const fechaActualUnix = Date.now();
//     //     if((fechaActualUnix / 1000) > tokenDecode.exp){
//     //         Swal.fire({
//     //             icon: 'warning',
//     //             title: 'Oops... :c',
//     //             text: 'Sesion caducada Ingrese sesion de nuevo.'
//     //         })
//     //         this.router.navigate(['/login']);
//     //         return false;
//     //     }else{
//     //         //console.log(`Estoy logueado: ${Math.round(tokenDecode.exp - (fechaActualUnix / 1000))}`);
//     //     }
//     //     return true;
//     // }
//     // this.router.navigate(['/login']);
//     // return false;

//   //   let isLoggedin :string | boolean = localStorage.getItem('isLoggedin') ? localStorage.getItem('isLoggedin') : false;
//   //   //console.log(isLoggedin);
//   //   if (isLoggedin) {
//   //     if(isLoggedin === "true"){
//   //       return true;
//   //     }
//   //     return this.exit();
//   //   }
//   //   return this.exit();
//   // }

//   // exit() : boolean{
//   //   localStorage.setItem('isLoggedin', "false");
//   //   localStorage.removeItem('idPerfil');
//   //   localStorage.removeItem('token')
//   //   localStorage.removeItem('idUsuario');
//   //   localStorage.removeItem('users');
//   //   this.router.navigate(['/login']);
//   //   return false;
//   // }

//   //End class AuthGuard
// }
