import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { Subject, BehaviorSubject, Observable } from 'rxjs';
// import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ControlCalidadService {

    constructor(private http: HttpClient) { }

    idPerfil: string = localStorage.getItem('idPerfil');
    //Api para obtener la data de control de calidad
    getControlCalidad(idUsuario, idRegion: number = 0, valTipo: number = 1) {
        return this.http.post(`${environment.apiUrl}/api/v1/control/get-calidad`, {
            "id_perfil": this.idPerfil,
            "id_region": idRegion,
            "val_tipo": valTipo,
        }).pipe(map(data => data));
    }

    getControlCoordinador(idUser :number = 0, idPerfil : number = 3){
        return this.http.post(`${environment.apiUrl}/api/v1/control/get-coordinador`, {
            "id_perfil": idPerfil
            , "id_usuario": idUser
        }).pipe(map(data => data));
    }

    getAlertaCoordinadores(){
        return this.http.post(`${environment.apiUrl}/api/v1/control/get-alerta-coordinadores`, {
            "id_perfil": this.idPerfil
        }).pipe(map(data => data));
    }

    getAlertaBajaDuracion( idPerfil : string = '3' ){
        return this.http.post(`${environment.apiUrl}/api/v1/control/get-alerta-baja-duracion`, {
            "id_perfil": idPerfil
        }).pipe(map(data => data));
    }

    downloadControlCalidad(idRegion: number = 0, valTipo: number = 1) {
        console.log('idPerfil: ', this.idPerfil);
        console.log('idRegion: ', idRegion);
        console.log('valTipo: ', valTipo);
        return this.http
        .post<any>(`${environment.apiUrl}/api/v1/control/get-download-calidad`, {
            "id_perfil": this.idPerfil,
            "id_region": idRegion,
            "val_tipo": valTipo,
        })
        .pipe(map(data => data));
    }

    downloadControlCoordinador(idUser :number = 0, idPerfil : number = 3) {
        return this.http
        .post<any>(`${environment.apiUrl}/api/v1/control/get-download-coordinador`, {
            "id_perfil": idPerfil
            , "id_usuario": idUser
        })
        .pipe(map(data => data));
    }
}