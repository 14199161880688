import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Encuesta } from "../../models/table-encuentas.model";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MostrarEncuestas } from "src/app/core/service/mostrar-encuentas.service";
import { DatePipe } from "@angular/common";
import { IncidenteEncuesta } from "../../../../../models/incidente-encuesta.model";
import { Observable, of as observableOf, merge, BehaviorSubject, fromEvent } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, SortDirection } from "@angular/material/sort";
import { HttpClient } from "@angular/common/http";
import Swal from "sweetalert2";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { DataSource } from "@angular/cdk/collections";
import { UnsubscribeOnDestroyAdapter } from "src/app/components/shared/UnsubscribeOnDestroyAdapter";
import { TableEncuestasCoordinadoresComponent } from "../../table-encuesta-coordinadores/table-encuestas.component";

export interface IncidenteData {
    fechaRegsitro: string;
    incidente: string;
    comentario: string;
}

interface Incidencia {
    value: string;
    viewValue: string;
}

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

// const Confirm = 

@Component({
    selector: 'app-incidente-modal',
    templateUrl: './incidente-modal.component.html',
    styleUrls: ['./incidente-modal.component.scss']
})
export class IncidenteModalComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

    // Variables Generales
    public isTblLoading = true;
    componentProps: Encuesta;
    pipe = new DatePipe('en-US');
    dataIncidentes: any;
    dataEncuesta: any;
    idEncuesta: any;
    estadoIncidencia: any;
    resultsLength: number = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    idPerfil: number = parseInt(localStorage.getItem('idPerfil'));
    idUsuarioLocal: string = localStorage.getItem('idUsuario');

    // Variables formulario para agregar nueva incidencia
    nmFechaIncidente: any;
    selectIncidencia: any;
    nmValueTextArea: any;
    ngFechaIncidente: any;
    formIncidente: any = this.fb.group({
        dataPickerIncidente: ['', Validators.required],
        selectIncidente: ['', Validators.required],
        textAreaComentario: new FormControl(),
        fcDateIncidente: new FormControl(new Date())
        // fcDateIncidente : this.fcDateIncidente
    });

    formDisabled: boolean = false;

    //Variables para la tabla de incidentes de la encuesta.
    filteredOptions: Observable<IncidenteEncuesta[]>;
    displayedColumns: string[] = ['fechaRegistro', 'incidente', 'comentario'];
    incidencias: Incidencia[] = [];
    exampleDatabase: MostrarEncuestas | null;
    dataSource: any;

    // Variables Child
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('filter', { static: true }) filter: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<IncidenteModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private fb: FormBuilder,
        private ws_mostrarEncuesta: MostrarEncuestas,
        public httpClient: HttpClient,
        //private test: TableEncuestasCoordinadoresComponent
    ) {
        super();
        this.componentProps = data;
    }

    ngOnInit() {
        this.dataIncidentes = this.data['incidencias'];
        this.dataEncuesta = this.data['dataEncuesta'];
        this.idEncuesta = this.data['dataEncuesta']['id_encuesta'];
        this.estadoIncidencia = this.data['dataEncuesta']['estado_incidencia']
        this.formDisabled = parseInt(this.estadoIncidencia) === 1 ? true : false;
        console.log(this.componentProps)
        //this.getListIncidentesEncuesta(this.idEncuesta);
        this.loadData();
        this.llenarSelectIncidentes();
    }

    finalizarIncidencias() {
       
        Swal.fire({
            title: 'Esta seguro?',
            text: "No se podra agregar mas incidencias en la encuesta.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, finalizar!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.dialogRef.close(true);
                this.ws_mostrarEncuesta.finalizarIncidenciasEncuesta(parseInt(this.idEncuesta)).subscribe(
                    ({ msg, data, status }) => {
                        if (parseInt(status) === 200) {
                            Toast.fire({
                                icon: "success",
                                title: msg
                            });
                            
                        } else {
                            console.error(msg);
                        }
                    }
                );
                
            }
        })
    }

    llenarSelectIncidentes() {
        this.incidencias = this.dataIncidentes.map(
            (item: any) => ({ value: item['incidente_id'], viewValue: item['incidente_texto_supervision'] })
        );
    }

    agregarIncidente(event) {
        let correcto: boolean = true;
        let msgError: string = 'Se debe:';
        const fechaRegistro = this.pipe.transform(this.formIncidente.value.dataPickerIncidente, 'yyyy/MM/dd');
        const idIncidente = this.formIncidente.value.selectIncidente;
        const comentario = this.nmValueTextArea;
        const idEncuesta = this.idEncuesta;

        if (fechaRegistro == null) {
            correcto = false;
            msgError += "<br> - <b>Seleccionar</b> una fecha."
        }
        if (idIncidente == undefined || idIncidente == 0) {
            correcto = false;
            msgError += "<br> - <b>Seleccionar</b> un incidente."
        }

        if (correcto) {
            this.dialogRef.close();
            this.ws_mostrarEncuesta.addIncidenteEncuesta(fechaRegistro, idEncuesta, comentario, idIncidente).subscribe(
                (res) => {
                    if (parseInt(res.status) === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: "Se registro el incidente correctamente."
                        });
                        
                    } else {
                        console.error(res.msg)
                    }
                }
            );
        } else {
            Toast.fire({
                icon: 'error',
                title: msgError
            });
        }
    }

    public loadData() {
        this.exampleDatabase = new MostrarEncuestas(this.httpClient);
        this.dataSource = new TablaIncidentesEncuestaDataSource(
            this.exampleDatabase,
            this.paginator,
            //this.sort,
            this.idEncuesta
        );
        // if (this.filter?.nativeElement) {
        //     this.subs.sink = fromEvent(this.filter?.nativeElement, 'keyup')
        //         .subscribe(() => {
        //             if (!this.dataSource) {
        //                 return;
        //             }
        //             this.isTblLoading = false;
        //             this.dataSource.filter = this.filter.nativeElement.value;
        //         }
        //         );
        // } else { this.isTblLoading = false; }
    }

}

// Clase para crear la tabla
export class TablaIncidentesEncuestaDataSource extends DataSource<IncidenteEncuesta> {
    filterChange = new BehaviorSubject('');
    get filter(): string {
        return this.filterChange.value;
    }
    set filter(filter: string) {
        this.filterChange.next(filter);
    }
    filteredData: IncidenteEncuesta[] = [];
    renderedData: IncidenteEncuesta[] = [];
    constructor(
        public mostrarEncuestasService: MostrarEncuestas,
        public paginator: MatPaginator,
        //public _sort: MatSort,
        public idEncuesta: string
    ) {
        super();
        // Reset to the first page when the user changes the filter.
        this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
    }
    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<IncidenteEncuesta[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.mostrarEncuestasService.dataChangeIncidentesEncuesta,
            // this._sort.sortChange,
            this.filterChange,
            this.paginator.page
        ];

        this.mostrarEncuestasService.getIncidentesEncuesta(this.idEncuesta);
        return merge(...displayDataChanges).pipe(
            map(() => {
                // Filter data
                this.filteredData = this.mostrarEncuestasService.dataIncidentesEncuesta
                    .slice()
                    .filter((resIncidenteEncuesta: IncidenteEncuesta) => {
                        const searchStr = (
                            resIncidenteEncuesta.fecha_registro_incidencia,
                            resIncidenteEncuesta.comentario,
                            resIncidenteEncuesta.texto_supervision
                        ).toLowerCase();
                        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
                    });
                // Sort filtered data
                const sortedData = this.sortData(this.filteredData.slice());
                sortedData.map((element: any) => element.fecha_registro_incidencia = element.fecha_registro_incidencia.slice(0,10));
                console.log(sortedData)
                const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
                this.renderedData = sortedData.splice(
                    startIndex,
                    this.paginator.pageSize
                );
                return this.renderedData;
            })
        );
    }
    disconnect() { }
    /** Returns a sorted copy of the database data. */
    sortData(data: IncidenteEncuesta[]): IncidenteEncuesta[] {
        return data;
    }
}