import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Encuesta } from '../../models/encuesta.model';
import { IncidenteEncuesta } from 'src/app/models/incidente-encuesta.model';

@Injectable({
  providedIn: 'root'
})
export class MostrarEncuestas {
  dataChange: BehaviorSubject<Encuesta[]> = new BehaviorSubject<Encuesta[]>([]);
  dataChangeIncidentesEncuesta: BehaviorSubject<IncidenteEncuesta[]> = new BehaviorSubject<IncidenteEncuesta[]>([]);
  constructor(private http: HttpClient) { }

  //Api para obtener las regiones que tengan encuestas asignadas o todas
  // getEncuestas( idUser : string = "" ) {
  //     const jsonParams = { "id_user": idUser }
  //     return this.http
  //         .post<any>(`${environment.apiUrl}/api/get-encuestas-user`, jsonParams)
  //         .pipe(map(data => data)).toPromise();
  // }
  get data(): Encuesta[] {
    return this.dataChange.value;
  }

  get dataIncidentesEncuesta(): IncidenteEncuesta[] {
    return this.dataChangeIncidentesEncuesta.value;
  }

  getEncuestas(idUser: string = "0", pTipoPerfil : string = "5" ): void {
    const jsonParams = { "id_user": idUser, "id_perfil_asignacion" : pTipoPerfil }
    this.http.post<any>(`${environment.apiUrl}/api/v1/encuesta/get-encuestas-user`, jsonParams).subscribe(
      (res) => {
        console.log(res);
        const data = res.data
        this.dataChange.next(data);
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      }
    );
  }

  addIncidenteEncuesta(fechaRegistro: any, idEncuesta: number, comentario: string = "", idIncidencia: any) {
    const jsonParams = {
      fecha_registro: fechaRegistro,
      id_encuesta: idEncuesta,
      texto_incidencia: comentario,
      id_incidencia: idIncidencia
    };

    return this.http
      .post<any>(`${environment.apiUrl}/api/save-incidencia-encuesta`, jsonParams)
      .pipe(map(data => data));
  }

  finalizarIncidenciasEncuesta(idEncuesta:number){
    const jsonParams = {
      id_encuesta : idEncuesta
    }

    return this.http
      .post<any>(`${environment.apiUrl}/api/finalizar-incidencias-encuesta`, jsonParams)
      .pipe(map(data => data));
  }

  getIncidentesEncuesta(idEncuesta :any){
    const jsonParams = {id_encuesta: idEncuesta};
    return this.http.post<any>(`${environment.apiUrl}/api/get-incidentes-encuesta`, jsonParams).subscribe(
      (res) => {
        console.log(res.data)
        const data = res.data;
        this.dataChangeIncidentesEncuesta.next(data);
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      }
    );
  }

}