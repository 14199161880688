<mat-nav-list >
  <!-- <a
    *ngFor="let item of optionsMenu"
    mat-list-item
    [routerLinkActive]="'active'"
    [routerLink]="['/'+ item.path]"
  >
    <mat-icon class="sidenav-icon">{{ item.data.icon }}</mat-icon>
    {{ item.data.text }}
  </a> -->
  
  <a
    mat-list-item
    [routerLinkActive]="'active'"
    [routerLink]="['/asignar-encuesta']"
    *ngIf="optionsPermisos.asignarEncuesta.activo == true"
  >
    <mat-icon class="sidenav-icon">library_add</mat-icon>
    Asignar Muestra
  </a>
  <a
    mat-list-item
    [routerLinkActive]="'active'"
    [routerLink]="['/encuestas']"
    *ngIf="optionsPermisos.encuestas.activo == true"
  >
    <mat-icon class="sidenav-icon">text_snippet</mat-icon>
    Encuestas Asignadas
  </a>
  <br/>
  <a
    mat-list-item
    [routerLinkActive]="'active'"
    [routerLink]="['/encuestas-coordinador']"
    *ngIf="optionsPermisos.encuestasCoordiandores.activo == true"
  >
    <mat-icon class="sidenav-icon">text_snippet</mat-icon>
    Encuestas Coordinadores Asignadas
  </a>
  <br/>
  <br/>
  <a
    mat-list-item
    [routerLinkActive]="'active'"
    [routerLink]="['/dashboard']"
    *ngIf="optionsPermisos.controlCalidad.activo == true"
  >
    <mat-icon class="sidenav-icon">dashboard</mat-icon>
    Control y Seguimiento Muestra
  </a>
  <br/>
  <a
    mat-list-item
    [routerLinkActive]="'active'"
    [routerLink]="['/control-calidad']"
    *ngIf="optionsPermisos.dashboard.activo == true"
  >
    <mat-icon class="sidenav-icon">drag_indicator</mat-icon>
    Control de Calidad
  </a>
  <a
    mat-list-item
    [routerLinkActive]="'active'"
    [routerLink]="['/reportes']"
    *ngIf="optionsPermisos.reportes.activo == true"
  >
    <mat-icon class="sidenav-icon">drag_indicator</mat-icon>
    Reportes MDSF
  </a>

  <a
    mat-list-item
    [routerLinkActive]="'active'"
    [routerLink]="['/dfc']"
    *ngIf="optionsPermisos.reportes.activo == true"
  >
    <mat-icon class="sidenav-icon">drag_indicator</mat-icon>
    DFC
  </a>
  <!-- <div class="nested-menu">
    <a mat-list-item (click)="showMenu = !showMenu">
      <img width="25" style="padding-right: 15px;" src="/assets/images/chile.png"/> Chile
    </a>
    <ul class="nested submenu" [class.expand]="showMenu">
      <li>
        <a routerLink="dashboard/chile/falabella-retail" [routerLinkActive]="['actives']">
          <span>Falabella Retail </span>
        </a>
      </li>
      <li>
        <a  routerLink="dashboard/chile/banco-falabella" [routerLinkActive]="['actives']" >
          <span>Banco Falabella</span>
        </a>
      </li>
      <li>
        <a routerLink="dashboard/chile/mall-plaza" [routerLinkActive]="['actives']">
          <span>Mall Plaza</span>
        </a>
      </li>
      <li>
        <a routerLink="dashboard/chile/sodimac" [routerLinkActive]="['actives']">
          <span>Sodimac</span>
        </a>
      </li>
      <li>
        <a routerLink="dashboard/chile/open" [routerLinkActive]="['actives']">
          <span>Open</span>
        </a>
      </li>
      <li>
        <a routerLink="dashboard/chile/tottus" [routerLinkActive]="['actives']">
          <span>Tottus</span>
        </a>
      </li>
      <li>
        <a routerLink="dashboard/chile/falabella_com" [routerLinkActive]="['actives']"  >
          <span>Falabella.com</span>
        </a>
      </li>
    </ul>
  </div> -->
  <!--div class="nested-menu">
    <a mat-list-item (click)="showMenu2 = !showMenu2">
      <img width="25" style="padding-right: 15px;" src="/assets/images/peru.png"/> Perú
    </a>
    <ul class="nested submenu" [class.expand]="showMenu2">
      <li>
        <a href="javascript:void(0)">
          <span>Falabella Retail</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)">
          <span>Banco Falabella</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)">
          <span>Mall Plaza</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)">
          <span>Sodimac</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)">
          <span>Open</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)">
          <span>Tottus</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="nested-menu">
    <a mat-list-item (click)="showMenu3 = !showMenu3">
      <img width="25" style="padding-right: 15px;" src="/assets/images/colombia.png"/> Colombia
    </a>
    <ul class="nested submenu" [class.expand]="showMenu3">
      <li>
        <a href="javascript:void(0)">
          <span>Falabella Retail</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)">    
          <span>Banco Falabella</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)">
          <span>Mall Plaza</span>
        </a>
      </li>
    </ul>
  </div-->
</mat-nav-list>
