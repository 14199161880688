import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { EncuestaModel } from 'src/app/components/home/asignacion-encuestas/encuesta.model';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    constructor(private http: HttpClient) { }

    getUsersPerfil(idUsu :string, idPerfil :string, listRegiones : [] = []){
        return this.http
        .post<any>(`${environment.apiUrl}/api/v1/usuario/get-users-perfil`, { "usu_id": idUsu, "perfil_id": idPerfil, "regiones" : listRegiones })
        .pipe(map(data => data));
    }

}