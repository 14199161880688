import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector: '[appRecarga]'
})
export class RegargaDirective implements OnChanges{

    @Input() appRecarga !: number;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef
    ){
        this.viewContainerRef.createEmbeddedView(templateRef);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('CAMBIOIOIOIOI')
        if( changes['appRecarga']){
            this.viewContainerRef.clear();
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}