import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
// import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralUtils {
  constructor() {}

  // Swal
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

}
