import { Component, OnInit } from '@angular/core';
// import { IndicadoresServices } from 'src/app/core/service/indicadores.service';
import { TypesServices } from 'src/app/core/service/types.service';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ControlCalidadService } from 'src/app/core/service/control-calidad.service';
import { GeneralUtils } from 'src/app/core/utils/general.utils';
import { AsignacionEncuestasService } from 'src/app/core/service/asignacion-encuestas.service';

interface comboSelect {
  label: string,
  value: number
}

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent implements OnInit{
  // LocalStorage
  // idPerfil: string = localStorage.getItem('idPerfil');
  idUsuario : string = localStorage.getItem('idUsuario');
  // Init vars
  regiones : comboSelect[] = [];
  sedes : comboSelect[] = [];
  comunas : comboSelect[] = [];
  coordinadores : comboSelect[] = [];
  // Table TAB 2
  disColCoordinador: string[] = ['nombre', 'preguntas_sin_respuesta', 'fuera_de_fecha', 'fuera_rango_tiempo', 'n_sin_respuestas_d','n_sin_respuestas_y','n_sin_respuestas_s', 'claves_sin_respuesta']; 
  dataCoordinador = []
  numeroCoordinador : number = 0
  textNombreCoordinador : string = 'Encuestador'
  opListCoordinador : string = "1"
  // Table TAB 1 - (Control de calidad) 1 
  opListCalidad : string = "1"
  disColCalidad: string[] = ['nombre', 'preguntas_sin_respuesta', 'fuera_de_fecha', 'fuera_rango_tiempo', 'n_sin_respuestas_d','n_sin_respuestas_y','n_sin_respuestas_s', 'claves_sin_respuesta']; 
  dataCalidad = []
  textCalidad : string = 'REGIÓN'
  // Table TAB 1 - (Control de calidad) 2
  opListCalidadDos : string = "1"
  disColCalidadDos: string[] = ['nombre', 'casos_fuera_muestra', 'validacion_hdr', 'validacion_cdf']; 
  dataCalidadDos = []
  textCalidadDos : string = 'REGIÓN'
  // Table TAB 3
  opTipoUser : string = "1"
  dataBajaDuracion = []
  disBajaDuracion = ['folio_casen', 'region', 'direccion', 'fono', 'coordinador', 'encuestador']
  dataAlertaGPS = []
  disAlertaGPS = ['folio_casen', 'region', 'direccion', 'fono', 'coordinador', 'encuestador']
  dataPreguntasClaves = []
  disPreguntasClaves = ['folio_casen', 'region', 'direccion', 'fono', 'coordinador', 'encuestador']
  // disColSupervision: string[] = ['nombre', 'no_entrevista_num', 'no_entrevista_por', 'problema_protocolo_num', 'problema_protocolo_por', 'hogar_fuera_muestra_num', 'hogar_fuera_muestra_por', 'hogar_problema_elegible_num', 'hogar_problema_elegible_por', 'tasa_logro_num','tasa_logro_por']; 
  // textNombreSupervision : string = 'REGIÓN';
  // dataSupervision = [];
  // listAlertas : comboSelect[] = [{value:1, label: "Alertas baja duración"}, {value:2, label: "Encuestas con GPS lejano al hoga"}, {value:3, label: "Encuestas con preguntas claves sin respuesta"}]




  constructor(
    private fb: FormBuilder,
    // private wsIndicadores: IndicadoresServices,
    private wsTypes: TypesServices,
    private wsControlCalidad: ControlCalidadService,
    private readonly route: ActivatedRoute,
    private generalUtils: GeneralUtils
    , private asignacionEncuestasService: AsignacionEncuestasService
  ) { }

  // Form validación
  frmIndCalidad = this.fb.group({
    selectRegiones: [0],
  });


  frmIndEncuestador = this.fb.group({
    selectCoordinadores: [0],
    // selectSedes: [0],
    // selectComunas: [0],
  });


  frmIndSupervision = this.fb.group({
    selectRegiones: [0],
  });

  frmIndAlertas = this.fb.group({
    selectAlertas : []
  });

  ngOnInit() {
    // LocalStorage 
    const idUsuario : string = localStorage.getItem('idUsuario');

    // Cargamos select de regiones, sedes, comunas.
    this.getSelectRegiones();
    // this.getSelectSedes();
    // this.getSelectComunas();
    this.getDataControlCalidad();
    this.getDataControlCoordinador();

    this.getCoordinadores(); // Obtener todos los coordinadores.
    this.getDataAlertaBajaDuracion();
    // Primera carga traemos todas las regiones.
    // this.getIndicadoresEncuestador(idUsuario, 1, 0, 0, 0);
    // this.getIndicadoresTrabajoCampo(idUsuario, 1, 0);
    // this.getIndicadoresSupervision(idUsuario, 1, 0);
  }

  getCoordinadores() {
    const idPerfil = `3`
    this.asignacionEncuestasService.getUsers(this.idUsuario, idPerfil).subscribe(
      ({ data, msg, status }) => {
        if (parseInt(status) === 200) {
          data.map((user: any) => this.coordinadores.push({ "label": user.nombre_user, "value": user.id_user }));
          // console.log(this.coordinadores);
          // console.log("coordinadores; ", data);
        } else {
          
        }
      });
  }

  async getDataControlCalidad(idRegion: number = 0, valTipo: number = 1) {
    // console.log('Ejecutando control calidad function')
    this.wsControlCalidad.getControlCalidad(this.idUsuario, idRegion, valTipo).subscribe((response:any) => {
      const {status, msg, data} = response;
      if(parseInt(status) === 200){
        this.dataCalidad = data;
      }else{
        console.error(msg)
      }
    });
  }

  async getDataControlCoordinador(idUser : number = 0) {
    // console.log('Ejecutando control Coordinador function');
    this.wsControlCalidad.getControlCoordinador(idUser).subscribe((response:any) => {
      const {status, msg, data} = response;
      if(parseInt(status) === 200){
        console.log(data);
        this.dataCoordinador = data;
      }else{
        console.error(msg)
      }
    });
  }

  async getDataAlertaBajaDuracion() {
    // console.log('Ejecutando control coordinadores function')
    const idPerfil = (this.opTipoUser === "1") ? '3' : '5';
    this.dataBajaDuracion = [];
    this.wsControlCalidad.getAlertaBajaDuracion(idPerfil).subscribe((response:any) => {
      const {status, msg, data} = response;
      if(parseInt(status) === 200){
        this.dataBajaDuracion = data;
      }else{
        console.error(msg)
      }
    });
  }

  async getSelectRegiones() {
    const idUsuario : number = parseInt(localStorage.getItem('idUsuario'));
    const idComuna : number = parseInt(this.frmIndEncuestador.value.selectComunas);
    this.regiones = [];
    this.wsTypes.getRegionesUser(idUsuario, idComuna).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        data.map((region: any) => this.regiones.push({ "label": region.label, "value": region.value }));
      } else {
        console.error(msg);
      }
    });
  }

  // async getSelectSedes() {
  //   const idUsuario : number = parseInt(localStorage.getItem('idUsuario'));
  //   const idRegion : number = parseInt(this.frmIndEncuestador.value.selectRegiones);
  //   const idComuna : number = parseInt(this.frmIndEncuestador.value.selectComunas);
  //   this.sedes = [];
  //   this.wsTypes.getSedesUser(idUsuario, idRegion, idComuna).subscribe(({ data, msg, status }) => {
  //     if (parseInt(status) === 200) {
  //       // console.log(data)
  //       data.map((region: any) => this.sedes.push({ "label": region.label, "value": region.value }));
  //     } else {
  //       console.error(msg);
  //     }
  //   });
  // }

  // async getSelectComunas() {
  //   const idUsuario : number = parseInt(localStorage.getItem('idUsuario'));
  //   const idRegion : number = parseInt(this.frmIndEncuestador.value.selectRegiones);
  //   const idSede : number = parseInt(this.frmIndEncuestador.value.selectSedes);
  //   this.comunas = []
  //   this.wsTypes.getComunasUser(idUsuario, idRegion, idSede).subscribe(({ data, msg, status }) => {
  //     if (parseInt(status) === 200) {
  //       // console.log(data)
  //       data.map((region: any) => this.comunas.push({ "label": region.label, "value": region.value }));
  //     } else {
  //       console.error(msg);
  //     }
  //   });
  // }

  // async getIndicadoresEncuestador( idUser : string
  //                                 , valTipo : number = 1
  //                                 , idRegion : number = 0
  //                                 , idSede : number = 0
  //                                 , idComuna : number = 0 ) {
  //   // console.log(`ID USER :: ${idUser} REGION :: ${idRegion} || TIPO :: ${valTipo}`)
  //   // console.log("llego getIndicadoresEncuestador...")
  //   // if ( val)
  //   this.wsIndicadores.getIndicadoresEncuestador(idUser, valTipo, idRegion, idSede, idComuna).subscribe(({ data, msg, status }) => {
  //     if (parseInt(status) === 200) {
  //       // console.log(data);
  //       switch (valTipo) {
  //         case 1:
  //           this.textNombreCoordinador = 'Encuestador'
  //           break;
        
  //         case 2:
  //           this.textNombreCoordinador = 'Coordinador'
  //           break;
  //       }
  //       this.dataCoordinador = data.data;
  //       this.numeroCoordinador = parseInt(data.total_numero_encuestadores);
  //     } else {
  //       console.error(msg);
  //     }
  //   });
  // }

  // async getIndicadoresTrabajoCampo( idUser : string, valTipo : number = 1, idRegion : number = 0) {
  //     // console.log(`ID USER :: ${idUser} REGION :: ${idRegion} || TIPO :: ${valTipo}`)
  //     this.wsIndicadores.getIndicadoresTrabajoCampo(idUser, valTipo, idRegion).subscribe(({ data, msg, status }) => {
  //       if (parseInt(status) === 200) {
  //         switch (valTipo) {
  //           case 1:
  //             this.textCalidad = 'REGIÓN'
  //             break;
  //           case 2:
  //             this.textCalidad = 'SEDE'
  //             break;
  //           case 3:
  //             this.textCalidad = 'COMUNA'
  //             break;
  //         }
  //         this.dataCalidad = data;
  //       } else {
  //         console.error(msg);
  //       }
  //     });
  // }

  // async getIndicadoresSupervision( idUser : string, valTipo : number = 1, idRegion : number = 0) {
  //   // console.log(`ID USER :: ${idUser} REGION :: ${idRegion} || TIPO :: ${valTipo}`)
  //   this.wsIndicadores.getIndicadoresSupervision(idUser, valTipo, idRegion).subscribe(({ data, msg, status }) => {
  //     if (parseInt(status) === 200) {
  //       switch (valTipo) {
  //         case 1:
  //           this.textNombreSupervision = 'REGIÓN'
  //           break;
  //         case 2:
  //           this.textNombreSupervision = 'SEDE'
  //           break;
  //         case 3:
  //           this.textNombreSupervision = 'COMUNA'
  //           break;
  //       }
  //       this.dataSupervision = data;
  //     } else {
  //       console.error(msg);
  //     }
  //   });
  // }

  // Click Button [HTML]
  filtroIndControlCoordinador() {
    const idUser : number = parseInt(this.frmIndEncuestador.value.selectCoordinadores);
    this.getDataControlCoordinador(idUser);
  }

  filtroIndControlCalidad() {

    // const idUsuario : string = localStorage.getItem('idUsuario');
    const valTipo : number = parseInt(this.opListCalidad);
    const idRegion : number = parseInt(this.frmIndCalidad.value.selectRegiones);
    // console.log('ID Region: ' + idRegion.toString())
    // console.log('Tipo: ' + valTipo.toString())
    this.getDataControlCalidad(idRegion, valTipo);
    // this.getIndicadoresTrabajoCampo( idUsuario, valTipo, idRegion);

  }

  filtroIndSupervision() {

    // const idUsuario : string = localStorage.getItem('idUsuario');
    // const valTipo : number = parseInt(this.opListSupervision);
    // const idRegion : number = parseInt(this.frmIndSupervision.value.selectRegiones);
    // this.getIndicadoresSupervision( idUsuario, valTipo, idRegion);

  }
  

  onChangeRadioIndEncuestadores(value : any){
    this.opListCoordinador = value;
  }

  onChangeRadioIndCalidad(value : any){
    this.opListCalidad = value;
  }

  // Change ComboBox
  //// Encuestadores
  onChangeComboBoxCoordinadores(){
    // this.getSelectRegiones();
    // this.getSelectSedes();
    // this.getSelectComunas();
  }

  onChangeRadioTipoUser(val:any){
    this.opTipoUser = val;
    this.getDataAlertaBajaDuracion();
  }


  onChangeComboBoxCalidad(){
    const idRegion : number = parseInt(this.frmIndCalidad.value.selectRegiones);
    if ( idRegion === 0 ){
      this.onChangeRadioIndCalidad(1);
    }else {
      const valTipo : number = parseInt(this.opListCalidad);
      if ( valTipo === 1 ) {
        this.onChangeRadioIndCalidad(2);
      } 
    }
  }
  
  downloadControlCalidad(){
    const valTipo : number = parseInt(this.opListCalidad);
    const idRegion : number = parseInt(this.frmIndCalidad.value.selectRegiones);
    // console.log('ID Region: ' + idRegion.toString())
    this.getDataControlCalidad(idRegion, valTipo);
    this.wsControlCalidad.downloadControlCalidad(idRegion, valTipo).subscribe({
      next: (res) => {
        if ( parseInt(res.status) === 200 ) {
          if (parseInt(res.data.status) === 200 ) {
            window.open(res.data.url, '_blank');
            this.generalUtils.Toast.fire({icon: 'success', title: 'Se descargó correctamente los datos.', timer: 3000});
          } else {
            this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
          }
        } else {
          this.generalUtils.Toast.fire({icon: 'info', title: res.msg, timer: 3000});
        }
      },
      error: (e) => {
        this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
      }
    });
  }

  downloadControlCoordinador(){
    const idUser : number = parseInt(this.frmIndEncuestador.value.selectCoordinadores);
    this.getDataControlCoordinador(idUser);
    this.wsControlCalidad.downloadControlCoordinador(idUser).subscribe({
      next: (res) => {
        if ( parseInt(res.status) === 200 ) {
          if (parseInt(res.data.status) === 200 ) {
            window.open(res.data.url, '_blank');
            this.generalUtils.Toast.fire({icon: 'success', title: 'Se descargó correctamente los datos.', timer: 3000});
          } else {
            this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
          }
        } else {
          this.generalUtils.Toast.fire({icon: 'info', title: res.msg, timer: 3000});
        }
      },
      error: (e) => {
        this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
      }
    });
  }

}