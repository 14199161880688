import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/core/service/dashboard.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
  });

  public formLogin: FormGroup;
  // site: string = "marcas-ciudadanas";
  site: string = "falabella";

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.formLogin = this.fb.group({
      username: ['',[
        Validators.required
      ]],
      password: ['',[
        Validators.required,
        Validators.minLength(4)
      ]]
    })
  }
  onLogin() {
    if(this.formLogin.valid){
      this.dashboardService.userLogIn(this.formLogin.value.username, this.formLogin.value.password).subscribe({
        next: (res) => {
          // console.log(res)
          if(parseInt(res.status) === 200){
            this.saveToken(res.token);
            localStorage.setItem('isLoggedin', 'true');
            localStorage.setItem('idPerfil', res.perfiles[0].id);
            localStorage.setItem('idUsuario', res.user_id);
            this.router.navigate(['']);
          } else if(res.status === 404){
            this.Toast.fire({
                icon: 'error',
                title: res.msg
            });
          }
        },
        error: (e) => {
          alert(e)
        }
      })
     
    }else{
      alert('Ingreso fallido')
    }
  }

  private saveToken(token: string) {
    localStorage.setItem('token', token);
  }
}
