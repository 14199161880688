import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found.component';
import { LayoutComponent } from './components/home/layout/layout.component';
import { AuthGuard } from './core/auth.guard';
import { FormEncuestasComponent } from './components/home/asignacion-encuestas/form-encuestas/form-encuestas.component';
import { ControlComponent } from './components/home/dashboard/control/control.component';
import { TableEncuestasComponent } from './components/home/encuestas/table-encuesta/table-encuestas.component';
import { IndicadoresComponent } from './components/home/dashboard/indicadores/indicadores.component';
import { ReportesComponent } from './components/home/reportes/reporte/reportes.component';
import { TableEncuestasCoordinadoresComponent } from './components/home/encuestas-coordiandores/table-encuesta-coordinadores/table-encuestas.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'inicio',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'asignar-encuesta', component: FormEncuestasComponent, canActivate: [AuthGuard]},
      {path: 'encuestas', component: TableEncuestasComponent, canActivate: [AuthGuard]},
      {path: 'dashboard', component: IndicadoresComponent, canActivate: [AuthGuard]},
      {path: 'control-calidad', component: ControlComponent, canActivate: [AuthGuard]},
      {path: 'reportes', component: ReportesComponent, canActivate: [AuthGuard]},
      {path: 'encuestas-coordinador', component: TableEncuestasCoordinadoresComponent, canActivate: [AuthGuard]},
    ]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
      //resolve: {a : TokenResolver}
  },
  {
    path: 'buscador',
    loadChildren: () =>
      import('./components/buscador/buscador.module').then((m) => m.BuscadorModule),
      //resolve: {a : TokenResolver}
  },
  {
    path: '**', component:PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
