

<mat-card class="card-incidente" [class.mat-elevation-z0]="true">
    
    <mat-card-header>
        <mat-card-title>Coordinador: <h5>{{componentProps['dataEncuesta'].nombre_usuario}}</h5>
        </mat-card-title>
        <mat-card-subtitle>Incidentes</mat-card-subtitle>
    </mat-card-header>
    <div style="text-align: right !important;">
        
        <button mat-raised-button color="warn" (click)="finalizarIncidencias()"
            [disabled]="formDisabled"><mat-icon>done</mat-icon><b>Finalizar</b></button>
    </div>
    <mat-card-content>
        <form [formGroup]="formIncidente" (ngSubmit)="agregarIncidente($event)" #f="ngForm">
            <mat-grid-list cols="3" rowHeight="100px" [gutterSize]="10">
                <mat-grid-tile>
                    <mat-form-field style="width: 100%;">
                        <mat-label>Fecha Incidente:</mat-label>
                        <input matInput [matDatepicker]="picker3" [(ngModel)]="ngFechaIncidente"
                            formControlName="dataPickerIncidente" placeholder="DD/MM/YYYY" autocomplete="off" required
                            [disabled]="formDisabled">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                    <mat-form-field appearance="fill" style="width: 100%; margin-left: 20px;">
                        <mat-label>Incidente</mat-label>
                        <mat-select [(ngModel)]="selectIncidencia" formControlName="selectIncidente"
                            [disabled]="formDisabled" placeholder="Seleccione una incidencia">
                            <mat-option *ngFor="let incidencia of incidencias" [value]="incidencia.value" required>
                                {{incidencia.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="3">

                    <div *ngIf="formDisabled" style="width: 100%; max-height: 70px;">
                        <mat-form-field appearance="fill" style="width: 100%; max-height: 70px; min-height: 100px;">
                            <mat-label>Comentarios</mat-label>
                            <input matInput type="text" [disabled]="formDisabled">
                            <mat-hint align="end">(Opcional)</mat-hint>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!formDisabled" style="width: 100%; max-height: 70px;">
                        <mat-form-field appearance="fill" style="width: 100%; max-height: 70px; min-height: 100px;">
                            <mat-label>Comentarios</mat-label>
                            <textarea matInput formControlName="textAreaComentario" [(ngModel)]="nmValueTextArea"
                                [readonly]="formDisabled" [disabled]="formDisabled"
                                placeholder="Comentarios sobre el incidente." style="resize: none;"></textarea>
                            <mat-hint align="end">(Opcional)</mat-hint>
                        </mat-form-field>
                    </div>


                </mat-grid-tile>
                <mat-grid-tile [colspan]="3" class="text-center">
                    
                    <button type="submit" mat-flat-button color="primary"
                        [disabled]="formDisabled"><mat-icon>note_add</mat-icon> Agregar</button>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
        <mat-divider></mat-divider>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z4 demo-table" style="margin-top: 10px;">
            <ng-container matColumnDef="fechaRegistro">
                <th mat-header-cell *matHeaderCellDef> Fecha Registro </th>
                <td mat-cell *matCellDef="let row"> {{row.fecha_registro_incidencia}} </td>
            </ng-container>
            <ng-container matColumnDef="incidente">
                <th mat-header-cell *matHeaderCellDef> Incidente </th>
                <td mat-cell *matCellDef="let row"> {{row.texto_supervision}} </td>
            </ng-container>
            <ng-container matColumnDef="comentario">
                <th mat-header-cell *matHeaderCellDef> Comentario </th>
                <td mat-cell *matCellDef="let row"> {{row.comentario}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3" align="center">No hay incidencias.</td>
            </tr>
        </table>
        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="5">
        </mat-paginator>
    </mat-card-content>
    <mat-card-footer>
        <div style="text-align: right !important;">
            <button mat-stroked-button mat-dialog-close style="margin: 5px;">Cerrar</button>
            <!-- <button mat-fab color="primary" aria-label="Example icon button with a delete icon">
                <mat-icon>delete</mat-icon>
              </button> -->
        </div>
    </mat-card-footer>
</mat-card>