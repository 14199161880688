import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { EncuestaModel } from 'src/app/components/home/asignacion-encuestas/encuesta.model';

@Injectable({
    providedIn: 'root'
})
export class AsignacionEncuestasService {

    // private _refresh$ = new Subject<void>();
    isTblLoading = true;
    dataChange: BehaviorSubject<EncuestaModel[]> = new BehaviorSubject<EncuestaModel[]>([]);
    $emitter = new EventEmitter();

    emitirEvento() {
        this.$emitter.emit();
    } 

    constructor(private http: HttpClient) { }

    // get refresh$(){
    //     return this._refresh$;
    // }

    get data(): EncuestaModel[] {
    return this.dataChange.value;
    }

    //Api para obtener las regiones que tengan encuestas asignadas o todas
    getRegiones(idRegiones: number[] = [], idComunas: number[] = []) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/types/region`, { "id_regiones": idRegiones, "id_comunas": idComunas })
            .pipe(map(data => data));
    }

    getComunas(idRegiones: number[] = [], idComuna: string = "") {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/types/comuna`, { "id_regiones": idRegiones, "id_comuna": idComuna })
            .pipe(map(data => data));
    }

    //Api para obtener los coordinadores regionales
    
    addEncuestas( params:any){
        return this.http
        .post<any>(`${environment.apiUrl}/api/v1/encuesta/asignar`, params
        ).pipe(map(data => data));
    }

    getUsers(idUsu :string, idPerfil :string){
        return this.http
        .post<any>(`${environment.apiUrl}/api/v1/usuario/get-users-perfil`, { "usu_id": idUsu, "perfil_id": idPerfil })
        .pipe(map(data => data));
    }

    getEncuestas(idUser:number = 1, idRegiones:number[] = [], idComunas: number[] = [], idPerfilAsignacion){
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/encuesta/get-encuestas`, 
                {
                    "id_user" : idUser
                    , "id_regiones" : idRegiones
                    , "id_comunas" : idComunas
                    , "id_perfil_asignacion" : idPerfilAsignacion
                }
            ).pipe(map(data => data));
    }

    //Api para obtener las encuestas asignadas a un usuario
    getUserEncuestas(idUser: number, pTipoPerfil : string = '5') : void{
        this.http.post<any>(`${environment.apiUrl}/api/v1/encuesta/get-encuestas-user`, { "id_user": idUser, "id_perfil_asignacion" : pTipoPerfil }).subscribe(
            (res) => {
                const data = res.data;
                this.isTblLoading = false;
                this.dataChange.next(data);
            },
            (error: HttpErrorResponse) => {
                this.isTblLoading = false;
                console.log(error.name + ' ' + error.message);
            }
        )
    }

    deleteEncuesta(idEncuesta: number){
        return this.http
        .post<any>(`${environment.apiUrl}/api/v1/encuesta/delete-asignada`, { "id_encuesta": idEncuesta })
        .pipe(map(data => data));
    }
    // getUserEncuestas(idUser: number){
    //     return this.http
    //     .post<any>(`${environment.apiUrl}/api/get-encuestas-user`, { "id_user": idUser })
    //     .pipe(map(data => data));
    // }


    // getListEncuestasUser(idUserPadre: number){
    //     return this.http
    //     .post<any>(`${environment.apiUrl}/api/list-encuestas-user`, { "id_user_padre": idUserPadre })
    //     .pipe(
    //         tap(() => {
    //             this._refresh$.next();
    //         }),
    //         map(data => data));
    // }

    
}