import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TableEncuestasComponent} from './table-encuesta/table-encuestas.component'


const routes: Routes = [
  {
    path: '',
    component: TableEncuestasComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EncuestasRoutingModule { }