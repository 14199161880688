import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, ChangeDetectorRef } from '@angular/core';
// import { CafService } from '../../core/service/caf.service';
// import { TypesService } from '../../core/service/types.service';
// General
// import { GeneralUtils } from 'src/app/core/utils/general.utils'

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';
// import { Caf } from '../caf.model';
// import { formatDate } from '@angular/common';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {
  action: string;
  dialogTitle: string;
  isDetails = false;
  cafForm: FormGroup;
  detail: any;
  objTypesDTE: object = [];
  closeModalCAF = 0;
  constructor(
    public dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // public cafService: CafService,
    // public typesService: TypesService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    // private generalUtils: GeneralUtils 
  ) {
    // Set the defaults
    this.action = data.action;
    console.log(data)
    if (this.action === 'details') {
      this.detail = data.detail;
      this.isDetails = true;
    } 
  }
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required')
      ? 'Required field'
      : this.formControl.hasError('email')
      ? 'Not a valid email'
      : '';
  }
  createContactForm(): FormGroup {
    // return this.fb.group({});
    return this.fb.group({
      selTipoDTE: ['', [Validators.required]],
      indicador_folio: ['', [Validators.required, Validators.minLength(0)]],
      file: [null, Validators.required]
    });
  }
  
  submit() {
    // emppty stuff
  }
  onNoClick(): void {
    this.dialogRef.close(this.closeModalCAF);
  }
  

  getColor(estado: string){
    // console.log(estado);
    let color = ''
    switch (estado) {
      case 'success':
        color = 'color-text-success';
        break;
      case 'warning':
        color = 'color-text-warn';
        break;
      case 'error':
        color = 'color-text-error';
        break;
      case 'primary':
        color = 'color-text-primary'
        break;
    }
    return color;
  }
  
}
