<mat-card>
  <mat-card-title style="font-size: 32px; font-family: Noto Sans, sans-serif !important;">
    <mat-icon class="customIconSize material-icons-outlined">arrow_forward</mat-icon>
    INDICADORES DE SEGUIMIENTO DE MUESTRA
  </mat-card-title>
  <!-- <mat-divider></mat-divider> -->
  <div class="row">
    <div class="col-lg-12">
      <mat-tab-group animationDuration="1000ms">
        <mat-tab label="TRABAJO ENCUESTADORES / COORDINADORES">
          <!-- Combobox indicadores encuestadores Región, Sede y Comuna. -->
          <mat-card-content class="mt-4">
            <form [formGroup]="frmIndEncuestador" style="padding: 20px 0 20px 0;">
              <div class="row">
                <div class="col-lg-5 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Región:</mat-label>
                    <mat-select formControlName="selectRegiones" (selectionChange)="onChangeComboBoxEncuestadores()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let region of regiones" [value]="region.value">
                        {{region.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <mat-form-field appearance="fill" style="width: 100%;padding: 0 0 0 0;">
                    <mat-label>Comunas:</mat-label>
                    <mat-select formControlName="selectComunas" (selectionChange)="onChangeComboBoxEncuestadores()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let comuna of comunas" [value]="comuna.value">
                        {{comuna.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-12 text-center mt-4 mb-2" >
                  <mat-radio-group name="opListEncuestadores" aria-label="Select an option">
                    <mat-radio-button name="opListEncuestadores" style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndEncuestadores(1)" value="1" [checked]="true">Encuestadores</mat-radio-button>
                    <mat-radio-button name="opListEncuestadores" style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndEncuestadores(2)" value="2">Coordinadores</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </form>
          </mat-card-content>  
          <!-- Boton filtrar indicadores encuestadores / coordinadores -->
          <mat-card-content style="text-align: center; padding: 0 0 0 0;">
            <button mat-flat-button color="primary" class="button-footer-card" (click)="filtroIndEncuestador()" style="padding: 0 20px 0 10px;">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">search</mat-icon>
              Buscar
            </button>
          </mat-card-content>
          <!-- Text encuestadores o coordinadores -->
          <mat-card-content style="padding: 0 0 0 0;" class="mt-4">
            <mat-card-title style="font-size: 20px;">
              <mat-icon class="material-icons-outlined">people</mat-icon>
              N° {{textNombreEncuestadores}}es con muestra asignada : {{ numeroEncuestadores }}
            </mat-card-title>
          </mat-card-content>
          <!-- Tabla indicadores encuestador / coordinador -->
          <mat-card-content style="text-align: center;">
            <table mat-table [dataSource]="dataEncuestadores" class="mat-elevation-z8">
              
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef>{{textNombreEncuestadores}}</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombre}} </td>
              </ng-container>

              <ng-container matColumnDef="region">
                <th mat-header-cell *matHeaderCellDef>REGIÓN</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.nombre_region}} </td>
              </ng-container>
              
              <ng-container matColumnDef="muestra_asignada">
                <th mat-header-cell *matHeaderCellDef>MUESTRA ASIGNADA</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.muestra_asignada}} </td>
              </ng-container>
              
              <ng-container matColumnDef="vivienda_no_visitadas">
                <th mat-header-cell *matHeaderCellDef>VIVIENDAS NO CONTACTADAS</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.vivienda_no_visitadas}} </td>
              </ng-container>
    
              <!-- <ng-container matColumnDef="direccion_con_problema">
                <th mat-header-cell *matHeaderCellDef>DIRECCIONES PROBLEMA DE MUESTRA</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.direccion_con_problema}} </td>
              </ng-container> -->
    
              <ng-container matColumnDef="direccion_no_elegibles">
                <th mat-header-cell *matHeaderCellDef>DIRECCIONES NO ELEGIBLES</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.direccion_no_elegibles}} </td>
              </ng-container>
    
              <ng-container matColumnDef="encuestas_logradas">
                <th mat-header-cell *matHeaderCellDef>ENCUESTAS LOGRADAS</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.encuestas_logradas}} </td>
              </ng-container>

              <!-- Cuando no se encuentran registros. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="disColEncuestadores.length">
                  Sin información.
                </td>
              </tr>
            
              <tr mat-header-row *matHeaderRowDef="disColEncuestadores"></tr>
              <tr mat-row *matRowDef="let row; columns: disColEncuestadores;"></tr>
        
            </table>
          </mat-card-content>
          <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadTrabajoEncuestadoresCoordinadores()">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content>
        </mat-tab>
        <mat-tab label="TRABAJO CATI POR REGIÓN">
          <!-- Combobox indicadores encuestadores Región, Sede y Comuna. -->
          <mat-card-content class="mt-4">
            <form [formGroup]="frmIndCatiRegion" style="padding: 20px 0 20px 0;">
              <div class="row">
                <div class="col-lg-5 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Región:</mat-label>
                    <mat-select formControlName="selectRegiones" (selectionChange)="onChangeComboBoxCatiRegion()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let region of regionesCatiRegion" [value]="region.value">
                        {{region.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <mat-form-field appearance="fill" style="width: 100%;padding: 0 0 0 0;">
                    <mat-label>Comunas:</mat-label>
                    <mat-select formControlName="selectComunas" (selectionChange)="onChangeComboBoxCatiRegion()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let comuna of comunasCatiRegion" [value]="comuna.value">
                        {{comuna.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </mat-card-content>  
          <!-- Boton filtrar indicadores encuestadores / coordinadores -->
          <mat-card-content style="text-align: center; padding: 0 0 0 0;">
            <button mat-flat-button color="primary" class="button-footer-card" (click)="filtroIndCatiRegion()" style="padding: 0 20px 0 10px;">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">search</mat-icon>
              Buscar
            </button>
          </mat-card-content>
          <!-- Text encuestadores o coordinadores -->
          <mat-card-content style="padding: 0 0 0 0;" class="mt-4">
            <mat-card-title style="font-size: 20px;">
              <mat-icon class="material-icons-outlined">people</mat-icon>
              N° muestra : {{ numeroTelefonico }}
            </mat-card-title>
          </mat-card-content>
          <!-- Tabla indicadores encuestador / coordinador -->
          <mat-card-content style="text-align: center;">
            <table mat-table [dataSource]="dataTelefonico" class="mat-elevation-z8">

              <ng-container matColumnDef="region">
                <th mat-header-cell *matHeaderCellDef>REGIÓN</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.nombre_region}} </td>
              </ng-container>
              
              <ng-container matColumnDef="muestra_asignada">
                <th mat-header-cell *matHeaderCellDef>MUESTRA ASIGNADA</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.muestra_asignada}} </td>
              </ng-container>
              
              <ng-container matColumnDef="vivienda_no_visitadas">
                <th mat-header-cell *matHeaderCellDef>VIVIENDAS NO VISITADAS</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.vivienda_no_visitadas}} </td>
              </ng-container>
    
              <!-- <ng-container matColumnDef="direccion_con_problema">
                <th mat-header-cell *matHeaderCellDef>DIRECCIONES PROBLEMA DE MUESTRA</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.direccion_con_problema}} </td>
              </ng-container> -->
    
              <ng-container matColumnDef="direccion_no_elegibles">
                <th mat-header-cell *matHeaderCellDef>DIRECCIONES NO ELEGIBLES</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.direccion_no_elegibles}} </td>
              </ng-container>
    
              <ng-container matColumnDef="encuestas_logradas">
                <th mat-header-cell *matHeaderCellDef>ENCUESTAS LOGRADAS</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.encuestas_logradas}} </td>
              </ng-container>

              <!-- Cuando no se encuentran registros. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="disColTelefonico.length">
                  Sin información.
                </td>
              </tr>
            
              <tr mat-header-row *matHeaderRowDef="disColTelefonico"></tr>
              <tr mat-row *matRowDef="let row; columns: disColTelefonico;"></tr>
        
            </table>
          </mat-card-content>
          <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadTrabajoCatiRegion()">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content>
        </mat-tab>
        <mat-tab label="TRABAJO CAMPO PRESENCIAL">
          <mat-card-content style="margin-top: 20px;">
            <form [formGroup]="frmIndTrabajo" style="padding: 20px 0 20px 0;">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Región:</mat-label>
                    <mat-select formControlName="selectRegiones" (selectionChange)="onChangeComboBoxTrabajoCampo()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let region of regiones" [value]="region.value">
                        {{region.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 text-center mt-4 mb-2" >
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndTrabajo(1)" value="1" 
                          name="opListTrabajo"
                          [disabled]="frmIndTrabajo.value.selectRegiones!=0"
                          [checked]="frmIndTrabajo.value.selectRegiones==0"
                          >
                          Regiones
                        </mat-radio-button>
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndTrabajo(3)" value="3" 
                          name="opListTrabajo"
                          [disabled]="frmIndTrabajo.value.selectRegiones==0"
                          [checked]="frmIndTrabajo.value.selectRegiones!=0"
                          >
                          Comunas
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-card-content>  
          <mat-card-content style="text-align: center;" class="mb-4">
            <button mat-flat-button color="primary" class="button-footer-card" (click)="filtroIndTrabajoCampo()" style="padding: 0 20px 0 10px;">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">search</mat-icon>
              Buscar
            </button>
          </mat-card-content>
    
          <!-- Tabla indicadores trabajo campo -->
          <mat-card-content style="text-align: center;">
            <table mat-table [dataSource]="dataTrabajoCampo" class="mat-elevation-z8">
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef>{{textNombreTrabajoCampo}}</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombre}} </td>
              </ng-container>
    
              <ng-container matColumnDef="tasa_contacto">
                <th mat-header-cell *matHeaderCellDef>TASA DE CONTACTO</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_contacto}}% </td>
              </ng-container>
              
              <ng-container matColumnDef="tasa_respuesta">
                <th mat-header-cell *matHeaderCellDef>TASA DE RESPUESTA</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_respuesta}}% </td>
              </ng-container>
    
              <ng-container matColumnDef="tasa_rechazo">
                <th mat-header-cell *matHeaderCellDef>TASA DE RECHAZO</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_rechazo}}% </td>
              </ng-container>
    
              <ng-container matColumnDef="tasa_cooperacion">
                <th mat-header-cell *matHeaderCellDef>TASA DE COOPERACIÓN</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_cooperacion}}% </td>
              </ng-container>
    
              <!-- <ng-container matColumnDef="tasa_logro">
                <th mat-header-cell *matHeaderCellDef>TASA DE LOGRO</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_logro}}% </td>
              </ng-container> -->
            
              <!-- Cuando no se encuentran registros. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="disColTrabajoCampo.length">
                  Sin información.
                </td>
              </tr>

              <tr mat-header-row *matHeaderRowDef="disColTrabajoCampo"></tr>
              <tr mat-row *matRowDef="let row; columns: disColTrabajoCampo;"></tr>
            </table>
          </mat-card-content>
    
          <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadTrabajoCampo()">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content>
        </mat-tab>
        <mat-tab label="TRABAJO CAMPO CATI">
          <mat-card-content style="margin-top: 20px;">
            <form [formGroup]="frmIndTrabajoCati" style="padding: 20px 0 20px 0;">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Región:</mat-label>
                    <mat-select formControlName="selectRegiones" (selectionChange)="onChangeComboBoxTrabajoCampoCati()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let region of regiones" [value]="region.value">
                        {{region.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 text-center mt-4 mb-2" >
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndTrabajoCati(1)" value="1" 
                          name="opListTrabajo"
                          [disabled]="frmIndTrabajoCati.value.selectRegiones!=0"
                          [checked]="frmIndTrabajoCati.value.selectRegiones==0"
                          >
                          Regiones
                        </mat-radio-button>
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndTrabajoCati(3)" value="3" 
                          name="opListTrabajo"
                          [disabled]="frmIndTrabajoCati.value.selectRegiones==0"
                          [checked]="frmIndTrabajoCati.value.selectRegiones!=0"
                          >
                          Comunas
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-card-content>  
          <mat-card-content style="text-align: center;" class="mb-4">
            <button mat-flat-button color="primary" class="button-footer-card" (click)="filtroIndTrabajoCampoCati()" style="padding: 0 20px 0 10px;">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">search</mat-icon>
              Buscar
            </button>
          </mat-card-content>
    
          <!-- Tabla indicadores trabajo campo -->
          <mat-card-content style="text-align: center;">
            <table mat-table [dataSource]="dataTrabajoCampoCati" class="mat-elevation-z8">
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef>{{textNombreTrabajoCampoCati}}</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombre}} </td>
              </ng-container>
    
              <ng-container matColumnDef="tasa_contacto">
                <th mat-header-cell *matHeaderCellDef>TASA DE CONTACTO</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_contacto}}% </td>
              </ng-container>
              
              <ng-container matColumnDef="tasa_respuesta">
                <th mat-header-cell *matHeaderCellDef>TASA DE RESPUESTA</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_respuesta}}% </td>
              </ng-container>
    
              <ng-container matColumnDef="tasa_rechazo">
                <th mat-header-cell *matHeaderCellDef>TASA DE RECHAZO</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_rechazo}}% </td>
              </ng-container>
    
              <ng-container matColumnDef="tasa_cooperacion">
                <th mat-header-cell *matHeaderCellDef>TASA DE COOPERACIÓN</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_cooperacion}}% </td>
              </ng-container>
    
              <!-- <ng-container matColumnDef="tasa_logro">
                <th mat-header-cell *matHeaderCellDef>TASA DE LOGRO</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.tasa_logro}}% </td>
              </ng-container> -->
            
              <!-- Cuando no se encuentran registros. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="disColTrabajoCampo.length">
                  Sin información.
                </td>
              </tr>

              <tr mat-header-row *matHeaderRowDef="disColTrabajoCampo"></tr>
              <tr mat-row *matRowDef="let row; columns: disColTrabajoCampo;"></tr>
            </table>
          </mat-card-content>
    
          <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadTrabajoCampoCati()">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content>
        </mat-tab>
        <mat-tab label="RESULTADO SUPERVISIÓN">
          <mat-card-content style="margin-top: 20px;">
            <form [formGroup]="frmIndSupervision" style="padding: 20px 0 20px 0;">
              <div class="row">
                <div class="col-lg-2 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Tipo Metodología:</mat-label>
                    <mat-select formControlName="selectTipoMetodologia" (selectionChange)="onChangeComboBoxMetodologia()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option [value]="1">Mobinet</mat-option>
                      <mat-option [value]="2">Cati</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Región:</mat-label>
                    <mat-select formControlName="selectRegiones" (selectionChange)="onChangeComboBoxSupervision()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let region of regiones" [value]="region.value">
                        {{region.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 text-center mt-4 mb-2" >
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndSupervision(1)" value="1" 
                          name="opListSupervision"
                          [disabled]="frmIndSupervision.value.selectRegiones!=0"
                          [checked]="frmIndSupervision.value.selectRegiones==0"
                          >
                          Regiones
                        </mat-radio-button>
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndSupervision(3)" value="3" 
                          name="opListSupervision"
                          [disabled]="frmIndSupervision.value.selectRegiones==0"
                          [checked]="frmIndSupervision.value.selectRegiones!=0"
                          >
                          Comunas
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-card-content>  
          <mat-card-content style="text-align: center;" class="mb-4">
            <button mat-flat-button color="primary" class="button-footer-card" (click)="filtroIndSupervision()" style="padding: 0 20px 0 10px;">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">search</mat-icon>
              Buscar
            </button>
          </mat-card-content>
    
          <!-- Tabla indicadores trabajo campo -->
          <mat-card-content>
            <table mat-table [dataSource]="dataSupervision" class="mat-elevation-z8">
    
              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
              <!-- Position Column display none-->
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"> No. </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombre}} </td>
              </ng-container>
            
              <ng-container matColumnDef="No">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">{{textNombreSupervision}}</th>
              </ng-container>
            
              <ng-container matColumnDef="no_entrevista_num">
                <th mat-header-cell *matHeaderCellDef align="center">N°</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.n_sin_incidentes}} </td>
              </ng-container>
              <ng-container matColumnDef="no_entrevista_por">
                <th mat-header-cell *matHeaderCellDef align="center">%</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.porcentaje_sin_incidentes}}% </td>
              </ng-container>
              
              <ng-container matColumnDef="problema_protocolo_num">
                <th mat-header-cell *matHeaderCellDef align="center">N°</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.n_incidentes_menores}} </td>
              </ng-container>
              <ng-container matColumnDef="problema_protocolo_por">
                <th mat-header-cell *matHeaderCellDef align="center">%</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.porcentaje_incidentes_menores}}% </td>
              </ng-container>
    
              <ng-container matColumnDef="hogar_fuera_muestra_num">
                <th mat-header-cell *matHeaderCellDef align="center">N°</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.n_incidentes_mayores}} </td>
              </ng-container>
              <ng-container matColumnDef="hogar_fuera_muestra_por">
                <th mat-header-cell *matHeaderCellDef align="center">%</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.porcentaje_incidentes_mayores}}% </td>
              </ng-container>
    
              <!-- <ng-container matColumnDef="hogar_problema_elegible_num">
                <th mat-header-cell *matHeaderCellDef align="center">N°</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.hogar_problema_elegible_num}} </td>
              </ng-container>
              <ng-container matColumnDef="hogar_problema_elegible_por">
                <th mat-header-cell *matHeaderCellDef align="center">%</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.hogar_problema_elegible_por}}% </td>
              </ng-container> -->
    
              <ng-container matColumnDef="tasa_logro_num">
                <th mat-header-cell *matHeaderCellDef align="center">N°</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.n_tasa_logro}} </td>
              </ng-container>
              <ng-container matColumnDef="tasa_logro_por">
                <th mat-header-cell *matHeaderCellDef align="center">%</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.porcentaje_tasa_logro}}% </td>
              </ng-container>
              
              <!-- Header row first group -->
              
              <!-- Header row second group -->
              <ng-container matColumnDef="no_entrevista">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" width="15%">SUPERVISIÓN<br/>SIN INCIDENTES</th>
              </ng-container>
              <ng-container matColumnDef="problemas_protocolo">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" width="15%">SUPERVISIÓN<br/>CON INCIDENTES MENORES</th>
              </ng-container>
              <ng-container matColumnDef="hogar_fuera_muestra">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" width="15%">SUPERVISIÓN<br/>CON INCIDENTES MAYORES</th>
              </ng-container>
              <!-- <ng-container matColumnDef="hogar_problemas_elegibilidad">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" width="15%">TASA DE LOGRO</th>
              </ng-container> -->
              <ng-container matColumnDef="tasa_logro">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" width="15%">TASA DE LOGRO</th>
              </ng-container>
    
              <!-- Cuando no se encuentran registros. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="disColSupervision.length" align="center">
                  Sin información.
                </td>
              </tr>

              <tr mat-header-row *matHeaderRowDef="['No', 'no_entrevista', 'problemas_protocolo', 'hogar_fuera_muestra', 'tasa_logro']"></tr>
              <tr mat-header-row *matHeaderRowDef="disColSupervision"></tr>
              <tr mat-row *matRowDef="let row; columns: disColSupervision;"></tr>
    
            </table>
          </mat-card-content>
    
          <!-- <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;"
            [disabled]="true"
            >
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content> -->
          <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadSupervision()">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content>
        </mat-tab>
        <!-- <mat-tab label="BBDD DFC">
          <mat-card-content style="text-align: center; padding: 80px 0 0 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" [disabled]="true">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <a mat-button href={{url}} target="_blank">Descargar BBDD DFC</a>
            </button>
          </mat-card-content> 
        </mat-tab> -->
      </mat-tab-group>
      <!-- Boton descarga historial encuestas por encuestador -->
      <!-- <mat-card-content style="text-align: right; padding: 0 20px 20px 0;">
        <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" [disabled]="true">
          <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
          <b>DESCARGAR HISTORIAL</b>
        </button>
      </mat-card-content> -->
    </div>
  </div>
</mat-card>