import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import { DashboardService } from "./dashboard.service";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

// import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

    constructor(private dashboardService: DashboardService, private readonly router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.dashboardService.show();
        const token = localStorage.getItem('token');

        // console.log('Token ===')
        //console.log(`Token === ${token}`)
        // console.log(req.url)
        //console.log('Interceptor activado')
        if( token ) {
            
            const cloned = req.clone({
                headers: req.headers.set("Authorization",
                    "Bearer " + token)
            });

            return next.handle( cloned ).pipe(
                finalize(
                    () => {
                        //console.log("Valid token")
                    // if ( req.url !== `${environment.apiUrl}/checkToken` ) {
                         
                    //     this.dashboardService.checkToken().subscribe({
                    //         next: (res) =>{
                    //             //console.log('Checking data')
                    //             // if ( parseInt(data.expiry_time) <= 0 && data.valid === true ) {
                    //             //     localStorage.removeItem('isLoggedin');
                    //             //     localStorage.removeItem('token')
                    //             //     Swal.fire({
                    //             //         title: 'La sesión ha expirado debe ingresar nuevamente.',
                    //             //         showDenyButton: false,
                    //             //         showCancelButton: false,
                    //             //         confirmButtonText: 'OK',
                    //             //     }).then((result) => {
                    //             //         // console.log(result)
                    //             //         this.router.navigate(['/login']);
                    //             //         /* Read more about isConfirmed, isDenied below */
                    //             //         // if (result.isConfirmed) {
                    //             //         //     Swal.fire('Saved!', '', 'success')
                    //             //         // } else if (result.isDenied) {
                    //             //         //     Swal.fire('Changes are not saved', '', 'info')
                    //             //         // }
                    //             //     })
                    //             // } else if ( parseInt(data.expiry_time) <= 0 && data.valid === true ) {
                    //             //     console.log("Token por expirado.")
                    //             // }
                    //         },
                    //         error: (e) => {
                    //             console.log(e);
                    //         }
                        //})
                    //}
                    this.dashboardService.hide();
                })
            );

        }else{
            return next.handle( req ).pipe(
                finalize( () => this.dashboardService.hide() )
            );
            // return next.handle(req);
        }

        
    }

}
