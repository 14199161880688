import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './components/shared/shared.module';
import { LoginModule } from './components/login/login.module';
import { InterceptorService } from './core/service/interceptor.service';
import { FormsModule } from '@angular/forms';
//import { LoadingComponent } from './shared/loading/loading.component';
import { LoadingModule } from './components/shared/loading/loading.module';
import { LayoutComponent } from './components/home/layout/layout.component';
import { TopNavComponent } from './components/home/layout/top-nav/top-nav.component';
import { SideNavComponent } from './components/home/layout/side-nav/side-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { HomeModule } from './components/home/home.module';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { EncuestasModule } from './components/home/encuestas/encuestas.module';
import { ControlModule } from './components/home/dashboard/control.module';
import { DashboardModule } from './components/home/dashboard/dashboard.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { AsignacionEncuestasModule } from './components/home/asignacion-encuestas/asignacion-encuestas.module';
import { EncuestasCoordinadorModule } from './components/home/encuestas-coordiandores/encuestas-coordinador.module';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    TopNavComponent, 
    SideNavComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    LoginModule,
    FormsModule,
    LoadingModule,
    MatSidenavModule,
    MatListModule,
    HomeModule,
    MatIconModule,
    MatToolbarModule,
    EncuestasModule,
    ControlModule,
    DashboardModule,
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    AsignacionEncuestasModule,
    EncuestasCoordinadorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
