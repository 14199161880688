import { Component, OnInit } from '@angular/core';
import { childRoutes } from '../../child-routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  routes: any = childRoutes;
  userPerfil :number  = parseInt(localStorage.getItem('idPerfil'));
  optionsMenu = [];

  // Asignar encuestas 1, 2 y 3
  // Encuestas 1 y 5
  // Control calidad 1, 2, 3 y 4
  // DashBord 1, 2, 3 y 4

  optionsPermisos:any = {
    asignarEncuesta: {
      activo: false,
      perfiles: [1, 2, 3]
    },
    encuestas:{
      activo: false, 
      perfiles:[1, 2, 3, 5]
    },
    controlCalidad: {
      activo: false,
      perfiles: [1, 2, 3, 4]
    },
    dashboard: {
      activo: false,
      perfiles: [1, 2, 3, 4]
    },
    reportes: {
      activo: false,
      perfiles: [1]
    },
    encuestasCoordiandores: {
      activo: false,
      perfiles: [1, 2, 3]
    }
  }

  ngOnInit() {
    // console.log('Este es el perfil acutal :=: ' + this.userPerfil);
    Object.keys(this.optionsPermisos).forEach((element:any) => {
      const res :boolean = this.optionsPermisos[element].perfiles.includes(this.userPerfil);
      if(res){
        this.optionsPermisos[element].activo = true;
      }
    });
  }
}