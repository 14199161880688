<form [formGroup]="formEncuestas" (ngSubmit)="fncConfirmarAsignacion($event)" #f="ngForm">
    <mat-card style="margin-bottom: 30px;">
        <mat-card-title>
            Asignación de {{textTitulo}}
        </mat-card-title>
        <mat-card-subtitle style="color: rgb(41, 146, 239);">
            <b>* Nota:</b> Los Campos que queden en blanco serán tomados como "Todas"
        </mat-card-subtitle>
        <mat-divider></mat-divider>
        <mat-card-content style="margin-top: 50px;">
            <div *ngIf="radioActivateButtons" class="row">
                <div class="col-lg-12 col-sm-12 text-center mt-4 mb-5">
                    <mat-radio-group name="isOptionListButton" aria-label="Select an option">
                        <mat-radio-button name="isOptionListButton" (change)="onChangeRadioButtons(1)" style="padding: 0 20px 0 30px;" value="1" [checked]="true">Coordinadores</mat-radio-button>
                        <mat-radio-button name="isOptionListButton" (change)="onChangeRadioButtons(2)" style="padding: 0 20px 0 30px;" value="2">Encuestadores</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <mat-grid-list cols="2" rowHeight="50px" [gutterSize]="'10px'">
                <mat-grid-tile [colspan]="2">
                    <mat-form-field appearance="fill" style="width: 100%; margin-left: 2px;">
                        <mat-label>Asignar a: </mat-label>
                        <mat-select formControlName="searchSelectCoordinador" [(ngModel)]="isSelectedUser">
                            <mat-option *ngFor="let user of listUsuarios" [value]="user" (click)="onClickCoordinadorSelected(user)">
                                {{user.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%; margin-left: 2px;">
                        <mat-label>{{auxText.labelRegion}}</mat-label>
                        <mat-select formControlName="selectRegiones" multiple [disabled]="formEncuestas.controls.fcRegion.value">
                            <mat-option #allSelectedRegion [value]="0" (click)="toggleAllSelection('selectRegiones', 'listRegiones', 'allSelectedRegion')">Todas</mat-option>
                            <mat-option *ngFor="let region of listRegiones" [value]="region.value"
                                (click)="tosslePerOne(allSelectedRegion.viewValue, 'fcRegion', 'allSelectedRegion', 'listRegiones', 'selectRegiones')">
                                {{region.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%; margin-left: 2px;">
                        <mat-label>{{auxText.labelComuna}}</mat-label>
                        <mat-select formControlName="selectComunas" multiple
                            [disabled]="formEncuestas.controls.fcComuna.value">
                            <mat-option #allSelectedComuna [value]="0"
                                (click)="toggleAllSelection('selectComunas', 'listComunas', 'allSelectedComuna')">Todas
                            </mat-option>
                            <mat-option *ngFor="let comuna of listComunas" [value]="comuna.value"
                                (click)="tosslePerOne(allSelectedComuna.viewValue, 'fcComuna', 'allSelectedComuna', 'listComunas', 'selectComunas')">
                                {{comuna.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="2">
                    <mat-form-field appearance="fill" style="width: 100%; margin-left: 2px;">
                        <mat-label>{{auxText.labelEncuestas}}</mat-label>
                        <mat-select formControlName="multiSelectEncuestas" multiple
                            [disabled]="formEncuestas.controls.fcEncuestas.value">
                            <mat-option #allSelectedEncuestas [value]="0" (click)="toggleAllSelection('multiSelectEncuestas', 'listEncuestas', 'allSelectedEncuestas')">
                                Todas
                            </mat-option>
                            <mat-option *ngFor="let encuesta of listEncuestas" [value]="encuesta.value"
                                (click)="tosslePerOne(allSelectedEncuestas.viewValue, 'fcEncuestas', 'allSelectedEncuestas', 'listEncuestas', 'multiSelectEncuestas')">
                                {{encuesta.label}}
                            </mat-option>
                        </mat-select>
                        <mat-hint style="color: red;"
                            *ngIf="formEncuestas.get('multiSelectEncuestas').touched && formEncuestas.get('multiSelectEncuestas').errors?.required">
                            Debe seleccionarse al menos una encuesta
                        </mat-hint>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

        </mat-card-content>
        <mat-card-footer style="text-align: right">
            <button type="submit" mat-flat-button color="primary" class="button-footer-card" style="width: 140px !important;">
                <mat-icon class="material-icons-two-tone">note_add</mat-icon>&nbsp;&nbsp;<b>Asignar</b>
            </button>
        </mat-card-footer>
    </mat-card>
</form>

<mat-card>
    <mat-card-title style="margin-bottom: 20px;"> Historial de Encuestas Asignadas</mat-card-title>
    <mat-card-content>
        <div class="body table-responsive">
            <table mat-table [dataSource]="dataSource" class="w-100 mb-4">

                <ng-container matColumnDef="idCadem">
                    <th mat-header-cell *matHeaderCellDef> FOLIO </th>
                    <td mat-cell *matCellDef="let element" align="center"> {{element.id_origen}} </td>
                </ng-container>

                <ng-container matColumnDef="idOrigenLabel">
                    <th mat-header-cell *matHeaderCellDef> Dirección </th>
                    <td mat-cell *matCellDef="let element"> {{element.direccion}} </td>
                </ng-container>

                <ng-container matColumnDef="fechaOrigen">
                    <th mat-header-cell *matHeaderCellDef> Fecha <br /> Encuesta </th>
                    <td mat-cell *matCellDef="let element" align="center"> {{element.fecha_encuesta_origen}} </td>
                </ng-container>

                <ng-container matColumnDef="fechaTope">
                    <th mat-header-cell *matHeaderCellDef> Fecha <br /> Tope </th>
                    <td mat-cell *matCellDef="let element" align="center"> {{element.fecha_tope ? element.fecha_tope : '-'}} </td>
                </ng-container>

                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef> Estado </th>
                    <td mat-cell *matCellDef="let element" align="center"> {{element.estado}} </td>
                </ng-container>

                <ng-container matColumnDef="nomRegion">
                    <th mat-header-cell *matHeaderCellDef> Region </th>
                    <td mat-cell *matCellDef="let element" align="center"> {{element.nombre_region}} </td>
                </ng-container>

                <ng-container matColumnDef="nomComuna">
                    <th mat-header-cell *matHeaderCellDef> Comuna </th>
                    <td mat-cell *matCellDef="let element" align="center"> {{element.nombre_comuna}} </td>
                </ng-container>

                <ng-container matColumnDef="eliminarEncuesta">
                    <th mat-header-cell *matHeaderCellDef> Eliminar </th>
                    <td mat-cell *matCellDef="let element" align="center">
                        <button mat-mini-fab color="warn" (click)="deleteEncuesta(element.encuesta_usuario)">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="direccion">
                    <th mat-header-cell *matHeaderCellDef> Dirección </th>
                    <td mat-cell *matCellDef="let element" align="center"> {{element.direccion}} </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow align="center">
                    <td class="mat-cell" colspan="9">
                        <div *ngIf="isTblLoading; else elseLoading" class="mt-4 mb-4">
                            <mat-progress-spinner color="primary" [diameter]="40" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                        <ng-template #elseLoading>Sin registros.</ng-template>
                    </td>
                </tr>
            </table>
            <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </mat-card-content>
    <mat-card-footer>
    </mat-card-footer>
</mat-card>