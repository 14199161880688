import { AuthGuard } from 'src/app/core/auth.guard';
import { UserResolver } from '../shared/resolver/asignar-encuestas.resolver';


export const childRoutes = [
  // {
  //   path: 'asignar-encuesta',
  //   loadChildren: () =>
  //     import('./asignacion-encuestas/asignacion-encuestas.module').then(m => m.AsignacionEncuestasModule),
  //   data: { icon: 'library_add', text: 'Asignar Muestra', perfiles:[1, 2, 3] },
  //   canActivate: [AuthGuard],
  //   resolve: {resolveResponse: TokenResolver, /*userList: UserResolver*/}
  // },
  // {
  //   path: 'encuestas',
  //   loadChildren: () =>
  //     import('./encuestas/encuestas.module').then(m => m.EncuestasModule),
  //   data: { icon: 'text_snippet', text: 'Encuestas Asignadas', perfiles:[1, 5] },
  //   canActivate: [AuthGuard],
  //   resolve: {resolveResponse: TokenResolver, userList: UserResolver}
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: () =>
  //     import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  //   data: { icon: 'dashboard', text: 'Control y Segumiento Muestra', perfiles:[1, 2, 3, 4] },
  //   canActivate: [AuthGuard],
  //   resolve: {resolveResponse: TokenResolver, userList: UserResolver}
  // },
  // {
  //   path: 'control-calidad',
  //   loadChildren: () =>
  //     import('./dashboard/control.module').then(m => m.ControlModule),
  //   data: { icon: 'drag_indicator', text: 'Control de Calidad', perfiles:[1, 2, 3, 4] },
  //   canActivate: [AuthGuard],
  //   resolve: {resolveResponse: TokenResolver, userList: UserResolver}
  // }
];
