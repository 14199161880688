import { Component, OnInit } from '@angular/core';
import { IndicadoresServices } from 'src/app/core/service/indicadores.service';
import { TypesServices } from 'src/app/core/service/types.service';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Download } from 'src/app/core/service/download.service';
import { GeneralUtils } from 'src/app/core/utils/general.utils';

interface comboSelect {
  label: string,
  value: number
}

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.scss']
})
export class IndicadoresComponent implements OnInit{
  // LocalStorage
  // idPerfil: string = localStorage.getItem('idPerfil');
  // idUsuario : string = localStorage.getItem('idUsuario');
  // Init vars
  url:string;
  regiones : comboSelect[] = [];
  sedes : comboSelect[] = [];
  comunas : comboSelect[] = [];
  regionesCatiRegion : comboSelect[] = [];
  comunasCatiRegion : comboSelect[] = [];
  // Table TAB 1
  disColEncuestadores: string[] = ['nombre', 'region', 'muestra_asignada', 'vivienda_no_visitadas', 'direccion_no_elegibles', 'encuestas_logradas']; 
  dataEncuestadores = []
  numeroEncuestadores : number = 0
  textNombreEncuestadores : string = 'Encuestador'
  opListEncuestadores : string = "1"
  // Table TAB 1.2
  disColTelefonico: string[] = ['region', 'muestra_asignada', 'vivienda_no_visitadas', 'direccion_no_elegibles', 'encuestas_logradas']; 
  dataTelefonico = []
  numeroTelefonico : number = 0
  // Table TAB 2
  // isDisabledRadioTrabajo = true;
  opListTrabajo : string = "1"
  disColTrabajoCampo: string[] = ['nombre', 'tasa_contacto', 'tasa_respuesta', 'tasa_rechazo', 'tasa_cooperacion']; 
  // disColTrabajoCampo: string[] = ['nombre', 'tasa_contacto', 'tasa_respuesta', 'tasa_rechazo', 'tasa_elegibilidad', 'tasa_logro']; 
  dataTrabajoCampo = []
  textNombreTrabajoCampo : string = 'REGIÓN'
  // Table TAB 2.1
  opListTrabajoCati : string = "1"
  disColTrabajoCampoCati: string[] = ['nombre', 'tasa_contacto', 'tasa_respuesta', 'tasa_rechazo', 'tasa_cooperacion']; 
  dataTrabajoCampoCati = []
  textNombreTrabajoCampoCati : string = 'REGIÓN'
  // Table TAB 3
  opListSupervision : string = "1"
  disColSupervision: string[] = ['nombre', 'no_entrevista_num', 'no_entrevista_por', 'problema_protocolo_num', 'problema_protocolo_por', 'hogar_fuera_muestra_num', 'hogar_fuera_muestra_por', 'tasa_logro_num','tasa_logro_por']; 
  textNombreSupervision : string = 'REGIÓN';
  dataSupervision = [];
  opListTipoMetodologia : string = "0"

  constructor(
    private fb: FormBuilder,
    private wsIndicadores: IndicadoresServices,
    private wsTypes: TypesServices,
    private readonly route: ActivatedRoute,
    private download: Download,
    private generalUtils: GeneralUtils
  ) { }

  // Form validación
  frmIndEncuestador = this.fb.group({
    selectRegiones: [0],
    // selectSedes: [0],
    selectComunas: [0],
  });

  frmIndCatiRegion = this.fb.group({
    selectRegiones: [0],
    // selectSedes: [0],
    selectComunas: [0],
  });

  frmIndTrabajo = this.fb.group({
    selectRegiones: [0],
  });

  frmIndTrabajoCati = this.fb.group({
    selectRegiones: [0],
  });

  frmIndSupervision = this.fb.group({
    selectRegiones: [0],
    selectTipoMetodologia: [0],
  });

  ngOnInit() {
    // LocalStorage 
    const idUsuario : string = localStorage.getItem('idUsuario');

    // Cargamos select de regiones, sedes, comunas.
    this.getSelectRegiones();
    // this.getSelectSedes();
    this.getSelectComunas();
    this.getSelectRegionesCatiRegion();
    this.getSelectComunasCatiRegion();

    // Primera carga traemos todas las regiones.
    this.getIndicadoresEncuestador(idUsuario, 1, 0, 0);
    this.getIndicadoresCatiRegion(idUsuario, 0, 0);
    this.getIndicadoresTrabajoCampo(idUsuario, 1, 0);
    this.getIndicadoresTrabajoCampoCati(idUsuario, 1, 0);
    this.getIndicadoresSupervision(idUsuario, 1, 0);
    // this.getDownload();
  }

  // getDownload(){
  //   this.download.getDownload().then(e=>{
  //     this.url = e.data.url;
  //     console.log(e.data.url);
  //   })
  // }

  async getSelectRegiones() {
    const idUsuario : number = parseInt(localStorage.getItem('idUsuario'));
    // const idSede : number = parseInt(this.frmIndEncuestador.value.selectSedes);
    const idComuna : number = parseInt(this.frmIndEncuestador.value.selectComunas);
    this.regiones = [];
    this.wsTypes.getRegionesUser(idUsuario, idComuna).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        data.map((region: any) => this.regiones.push({ "label": region.label, "value": region.value }));
      } else {
        console.error(msg);
      }
    });
  }

  async getSelectComunas() {
    const idUsuario : number = parseInt(localStorage.getItem('idUsuario'));
    const idRegion : number = parseInt(this.frmIndEncuestador.value.selectRegiones);
    this.comunas = []
    this.wsTypes.getComunasUser(idUsuario, idRegion).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        // console.log(data)
        data.map((region: any) => this.comunas.push({ "label": region.label, "value": region.value }));
      } else {
        console.error(msg);
      }
    });
  }

  async getSelectRegionesCatiRegion() {
    const idUsuario : number = parseInt(localStorage.getItem('idUsuario'));
    // const idSede : number = parseInt(this.frmIndEncuestador.value.selectSedes);
    const idComuna : number = parseInt(this.frmIndCatiRegion.value.selectComunas);
    this.regionesCatiRegion = [];
    this.wsTypes.getRegionesUser(idUsuario, idComuna).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        data.map((region: any) => this.regionesCatiRegion.push({ "label": region.label, "value": region.value }));
      } else {
        console.error(msg);
      }
    });
  }

  async getSelectComunasCatiRegion() {
    const idUsuario : number = parseInt(localStorage.getItem('idUsuario'));
    const idRegion : number = parseInt(this.frmIndCatiRegion.value.selectRegiones);
    this.comunasCatiRegion = []
    this.wsTypes.getComunasUser(idUsuario, idRegion).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        // console.log(data)
        data.map((region: any) => this.comunasCatiRegion.push({ "label": region.label, "value": region.value }));
      } else {
        console.error(msg);
      }
    });
  }

  async getIndicadoresEncuestador( idUser : string
                                  , valTipo : number = 1
                                  , idRegion : number = 0
                                  , idComuna : number = 0 ) {
    // console.log(`ID USER :: ${idUser} REGION :: ${idRegion} || TIPO :: ${valTipo}`)
    // console.log("llego getIndicadoresEncuestador...")
    // if ( val)
    this.wsIndicadores.getIndicadoresEncuestador(idUser, valTipo, idRegion, idComuna).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        // console.log(data);
        switch (valTipo) {
          case 1:
            this.textNombreEncuestadores = 'Encuestador'
            break;
        
          case 2:
            this.textNombreEncuestadores = 'Coordinador'
            break;
        }
        this.dataEncuestadores = data.data;
        this.numeroEncuestadores = parseInt(data.total_numero_encuestadores);
      } else {
        console.error(msg);
      }
    });
  }

  async getIndicadoresCatiRegion( idUser : string, idRegion : number = 0, idComuna : number = 0 ) {
    // console.log(`ID USER :: ${idUser} REGION :: ${idRegion} || TIPO :: ${valTipo}`)
    // console.log("llego getIndicadoresEncuestador...")
    // if ( val)
    this.wsIndicadores.getIndicadoresCatiRegion(idUser, idRegion, idComuna).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        this.dataTelefonico = data.data;
        this.numeroTelefonico = parseInt(data.total_numero_encuestadores);
      } else {
        console.error(msg);
      }
    });
  } 

  async getIndicadoresTrabajoCampo( idUser : string, valTipo : number = 1, idRegion : number = 0) {
      // console.log(`ID USER :: ${idUser} REGION :: ${idRegion} || TIPO :: ${valTipo}`)
      this.wsIndicadores.getIndicadoresTrabajoCampo(idUser, valTipo, idRegion).subscribe(({ data, msg, status }) => {
        if (parseInt(status) === 200) {
          switch (valTipo) {
            case 1:
              this.textNombreTrabajoCampo = 'REGIÓN'
              break;
            case 3:
              this.textNombreTrabajoCampo = 'COMUNA'
              break;
          }
          this.dataTrabajoCampo = data;
        } else {
          console.error(msg);
        }
      });
  }

  async getIndicadoresTrabajoCampoCati( idUser : string, valTipo : number = 1, idRegion : number = 0) {
    // console.log(`ID USER :: ${idUser} REGION :: ${idRegion} || TIPO :: ${valTipo}`)
    this.wsIndicadores.getIndicadoresTrabajoCampo(idUser, valTipo, idRegion, "2").subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        switch (valTipo) {
          case 1:
            this.textNombreTrabajoCampoCati = 'REGIÓN'
            break;
          case 3:
            this.textNombreTrabajoCampoCati = 'COMUNA'
            break;
        }
        this.dataTrabajoCampoCati = data;
      } else {
        console.error(msg);
      }
    });
}

  async getIndicadoresSupervision( idUser : string, valTipo : number = 1, idRegion : number = 0, tipoMetdologia : number = 0) {
    // console.log(`ID USER :: ${idUser} REGION :: ${idRegion} || TIPO :: ${valTipo}`)
    this.wsIndicadores.getIndicadoresSupervision(idUser, valTipo, idRegion, tipoMetdologia).subscribe(({ data, msg, status }) => {
      if (parseInt(status) === 200) {
        switch (valTipo) {
          case 1:
            this.textNombreSupervision = 'REGIÓN'
            break;
          // case 2:
          //   this.textNombreSupervision = 'SEDE'
          //   break;
          case 3:
            this.textNombreSupervision = 'COMUNA'
            break;
        }
        this.dataSupervision = data;
      } else {
        console.error(msg);
      }
    });
  }

  // Click Button [HTML]
  filtroIndEncuestador() {

    const idUsuario : string = localStorage.getItem('idUsuario');
    const valTipo : number = parseInt(this.opListEncuestadores);
    const idRegion : number = parseInt(this.frmIndEncuestador.value.selectRegiones);
    const idComuna : number = parseInt(this.frmIndEncuestador.value.selectComunas);
    this.getIndicadoresEncuestador( idUsuario, valTipo, idRegion, idComuna);

  }

  filtroIndCatiRegion() {
    const idUsuario : string = localStorage.getItem('idUsuario');
    const idRegion : number = parseInt(this.frmIndCatiRegion.value.selectRegiones);
    const idComuna : number = parseInt(this.frmIndCatiRegion.value.selectComunas);
    this.getIndicadoresCatiRegion( idUsuario, idRegion, idComuna);
  }

  filtroIndTrabajoCampo() {

    const idUsuario : string = localStorage.getItem('idUsuario');
    const valTipo : number = parseInt(this.opListTrabajo);
    const idRegion : number = parseInt(this.frmIndTrabajo.value.selectRegiones);
    this.getIndicadoresTrabajoCampo( idUsuario, valTipo, idRegion);

  }

  filtroIndTrabajoCampoCati() {
    const idUsuario : string = localStorage.getItem('idUsuario');
    const valTipo : number = parseInt(this.opListTrabajoCati);
    const idRegion : number = parseInt(this.frmIndTrabajoCati.value.selectRegiones);
    this.getIndicadoresTrabajoCampoCati( idUsuario, valTipo, idRegion);
  }

  filtroIndSupervision() {

    const idUsuario : string = localStorage.getItem('idUsuario');
    const tipoMetdologia : number = parseInt(this.opListTipoMetodologia);
    // console.log('TIPO METODOLOGÍA: ',tipoMetdologia);
    const valTipo : number = parseInt(this.opListSupervision);
    const idRegion : number = parseInt(this.frmIndSupervision.value.selectRegiones);
    this.getIndicadoresSupervision( idUsuario, valTipo, idRegion, tipoMetdologia);

  }
  

  onChangeRadioIndEncuestadores(value : any){
    this.opListEncuestadores = value;
  }

  onChangeRadioIndTrabajo(value : any){
    this.opListTrabajo = value;
  }

  onChangeRadioIndTrabajoCati(value : any){
    this.opListTrabajoCati = value;
  }

  onChangeRadioIndSupervision(value : any){
    this.opListSupervision = value;
  }

  onChangeRadioTipoMetodologia(value : any){
    this.opListTipoMetodologia = value;
  }

  // Change ComboBox
  //// Encuestadores
  onChangeComboBoxEncuestadores(){
    this.getSelectRegiones();
    this.getSelectComunas();
  }

  onChangeComboBoxCatiRegion(){
    this.getSelectRegionesCatiRegion();
    this.getSelectComunasCatiRegion();
  }

  onChangeComboBoxTrabajoCampo(){
    const idRegion : number = parseInt(this.frmIndTrabajo.value.selectRegiones);
    if ( idRegion === 0 ){
      this.onChangeRadioIndTrabajo(1);
    } else {
      this.onChangeRadioIndTrabajo(3);
    }
  }

  onChangeComboBoxTrabajoCampoCati(){
    const idRegion : number = parseInt(this.frmIndTrabajoCati.value.selectRegiones);
    if ( idRegion === 0 ){
      this.onChangeRadioIndTrabajoCati(1);
    } else {
      this.onChangeRadioIndTrabajoCati(3);
    }
  }

  onChangeComboBoxSupervision(){
    const idRegion : number = parseInt(this.frmIndSupervision.value.selectRegiones);
    if ( idRegion === 0 ){
      this.onChangeRadioIndSupervision(1);
    }else {
      this.onChangeRadioIndSupervision(3);
    }
  }

  onChangeComboBoxMetodologia(){
    const idMetodologia : number = parseInt(this.frmIndSupervision.value.selectTipoMetodologia);
    this.onChangeRadioTipoMetodologia( idMetodologia );
    // if ( idMetodologia === 0 ){
    //   this.onChangeRadioTipoMetodologia(1);
    // }eli {
    //   this.onChangeRadioTipoMetodologia(2);
    // }
  }
  

  downloadTrabajoEncuestadoresCoordinadores(){
    const idUsuario : string = localStorage.getItem('idUsuario');
    const valTipo : number = parseInt(this.opListEncuestadores);
    const idRegion : number = parseInt(this.frmIndEncuestador.value.selectRegiones);
    const idComuna : number = parseInt(this.frmIndEncuestador.value.selectComunas);
    this.getIndicadoresEncuestador(idUsuario, valTipo, idRegion, idComuna);
    this.wsIndicadores.downloadIndicadoresEncuestador(idUsuario, valTipo, idRegion, idComuna).subscribe({
      next: (res) => {
        if ( parseInt(res.status) === 200 ) {
          if (parseInt(res.data.status) === 200 ) {
            window.open(res.data.url, '_blank');
            this.generalUtils.Toast.fire({icon: 'success', title: 'Se descargó correctamente los datos.', timer: 3000});
          } else {
            this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
          }
        } else {
          this.generalUtils.Toast.fire({icon: 'info', title: res.msg, timer: 3000});
        }
      },
      error: (e) => {
        this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
      }
    });
  }

  downloadTrabajoCatiRegion(){
    const idUsuario : string = localStorage.getItem('idUsuario');
    const idRegion : number = parseInt(this.frmIndCatiRegion.value.selectRegiones);
    const idComuna : number = parseInt(this.frmIndCatiRegion.value.selectComunas);
    this.getIndicadoresCatiRegion(idUsuario, idRegion, idComuna);
    this.wsIndicadores.downloadIndicadoresCatiRegion(idUsuario, idRegion, idComuna).subscribe({
      next: (res) => {
        if ( parseInt(res.status) === 200 ) {
          if (parseInt(res.data.status) === 200 ) {
            window.open(res.data.url, '_blank');
            this.generalUtils.Toast.fire({icon: 'success', title: 'Se descargó correctamente los datos.', timer: 3000});
          } else {
            this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
          }
        } else {
          this.generalUtils.Toast.fire({icon: 'info', title: res.msg, timer: 3000});
        }
      },
      error: (e) => {
        this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
      }
    });
  }

  downloadTrabajoCampo(){
    const idUsuario : string = localStorage.getItem('idUsuario');
    const valTipo : number = parseInt(this.opListTrabajo);
    const idRegion : number = parseInt(this.frmIndTrabajo.value.selectRegiones);
    this.getIndicadoresTrabajoCampo( idUsuario, valTipo, idRegion);
    this.wsIndicadores.downloadIndicadoresTrabajoCampo(idUsuario, valTipo, idRegion).subscribe({
      next: (res) => {
        if ( parseInt(res.status) === 200 ) {
          if (parseInt(res.data.status) === 200 ) {
            window.open(res.data.url, '_blank');
            this.generalUtils.Toast.fire({icon: 'success', title: 'Se descargó correctamente los datos.', timer: 3000});
          } else {
            this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
          }
        } else {
          this.generalUtils.Toast.fire({icon: 'info', title: res.msg, timer: 3000});
        }
      },
      error: (e) => {
        this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
      }
    });
  }

  downloadTrabajoCampoCati(){
    const idUsuario : string = localStorage.getItem('idUsuario');
    const valTipo : number = parseInt(this.opListTrabajoCati);
    const idRegion : number = parseInt(this.frmIndTrabajoCati.value.selectRegiones);
    this.getIndicadoresTrabajoCampoCati( idUsuario, valTipo, idRegion);
    this.wsIndicadores.downloadIndicadoresTrabajoCampo(idUsuario, valTipo, idRegion, "2").subscribe({
      next: (res) => {
        if ( parseInt(res.status) === 200 ) {
          if (parseInt(res.data.status) === 200 ) {
            window.open(res.data.url, '_blank');
            this.generalUtils.Toast.fire({icon: 'success', title: 'Se descargó correctamente los datos.', timer: 3000});
          } else {
            this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
          }
        } else {
          this.generalUtils.Toast.fire({icon: 'info', title: res.msg, timer: 3000});
        }
      },
      error: (e) => {
        this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
      }
    });
  }

  downloadSupervision(){
    const idUsuario : string = localStorage.getItem('idUsuario');
    const tipoMetdologia : number = parseInt(this.opListTipoMetodologia);
    const valTipo : number = parseInt(this.opListSupervision);
    const idRegion : number = parseInt(this.frmIndSupervision.value.selectRegiones);
    this.getIndicadoresSupervision( idUsuario, valTipo, idRegion, tipoMetdologia);
    this.wsIndicadores.downloadSupervision(idUsuario, valTipo, idRegion, tipoMetdologia).subscribe({
      next: (res) => {
        if ( parseInt(res.status) === 200 ) {
          if (parseInt(res.data.status) === 200 ) {
            window.open(res.data.url, '_blank');
            this.generalUtils.Toast.fire({icon: 'success', title: 'Se descargó correctamente los datos.', timer: 3000});
          } else {
            this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
          }
        } else {
          this.generalUtils.Toast.fire({icon: 'info', title: res.msg, timer: 3000});
        }
      },
      error: (e) => {
        this.generalUtils.Toast.fire({icon: 'error', title: 'Ocurrió un error al descargar los datos.', timer: 3000});
      }
    });
  }
}