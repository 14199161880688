<h2 mat-dialog-title>Confirmar Asignación &nbsp; >> &nbsp; <b>{{user}}</b></h2>
<mat-divider></mat-divider>
<!-- <h4 style="color: black; margin-top: 5px; padding: 10px 0 10px 0;">Asignando a: <b>{{user}}</b></h4>
<mat-divider></mat-divider> -->
<mat-dialog-content class="mat-typography" style="width: auto;">
  <table mat-table [dataSource]="encuestas" class="table">
    <!-- <ng-container matColumnDef="id" class="mat-header-row">
      <th mat-header-cell *matHeaderCellDef> id </th>
      <td mat-cell *matCellDef="let element" align="center"> {{element.id}} </td>
    </ng-container> -->
    <ng-container matColumnDef="name" class="mat-header-row">
      <th mat-header-cell *matHeaderCellDef style="color: black; vertical-align: middle;"> Nombre </th>
      <td mat-cell *matCellDef="let element" align="center"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="region" class="mat-header-row">
      <th mat-header-cell *matHeaderCellDef style="color: black; vertical-align: middle;"> Region </th>
      <td mat-cell *matCellDef="let element" align="center"> {{element.region}} </td>
    </ng-container>
    <ng-container matColumnDef="comuna" class="mat-header-row">
      <th mat-header-cell *matHeaderCellDef style="color: black; vertical-align: middle;"> Comuna </th>
      <td mat-cell *matCellDef="let element" align="center"> {{element.comuna}} </td>
    </ng-container>
    <ng-container matColumnDef="direccion" class="mat-header-row">
      <th mat-header-cell *matHeaderCellDef style="color: black; vertical-align: middle;"> Dirección </th>
      <td mat-cell *matCellDef="let element" align="center"> {{element.direccion}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cancelar</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Confirmar</button>
</mat-dialog-actions>