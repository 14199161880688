<mat-card>
  <mat-card-title style="font-size: 32px; font-family: Noto Sans, sans-serif !important;">
    <mat-icon class="customIconSize material-icons-outlined">arrow_forward</mat-icon>
    INDICADORES DE CONTROL DE CALIDAD
  </mat-card-title>
  <!-- <mat-divider></mat-divider> -->
  <div class="row">
    <div class="col-lg-12">
      <mat-tab-group animationDuration="1000ms">
        <mat-tab label="CONTROL CALIDAD 1">
          <mat-card-content style="margin-top: 20px;">
            <form [formGroup]="frmIndCalidad" style="padding: 20px 0 20px 0;">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Región:</mat-label>
                    <mat-select formControlName="selectRegiones" (selectionChange)="onChangeComboBoxCalidad()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let region of regiones" [value]="region.value">
                        {{region.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 text-center mt-4 mb-2" >
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndCalidad(1)" 
                          value="1" 
                          name="opListCalidad"
                          [checked]="opListCalidad=='1'"
                          >
                          Presencial
                        </mat-radio-button>
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndCalidad(2)" 
                          value="2" 
                          name="opListCalidad"
                          >
                          Telefónico
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-card-content>  
          <mat-card-content style="text-align: center;" class="mb-4">
            <button mat-flat-button color="primary" class="button-footer-card" (click)="filtroIndControlCalidad()" style="padding: 0 20px 0 10px;">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">search</mat-icon>
              Buscar
            </button>
          </mat-card-content>
    
          <!-- Tabla indicadores trabajo campo -->
          <mat-card-content style="text-align: center;">
            <table mat-table [dataSource]="dataCalidad" class="mat-elevation-z8">
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef>{{textCalidad}}</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.rgn_nombre}} </td>
              </ng-container>

              <ng-container matColumnDef="preguntas_sin_respuesta">
                <th mat-header-cell *matHeaderCellDef>Promedio de preguntas<br/>sin respuesta</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.promedio_preguntas_sin_respuesta}} </td>
              </ng-container>
              
              <ng-container matColumnDef="fuera_de_fecha">
                <th mat-header-cell *matHeaderCellDef>Encuestas fuera<br/>de fecha</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.encuestas_fuera_fecha}} </td>
              </ng-container>
    
              <ng-container matColumnDef="fuera_rango_tiempo">
                <th mat-header-cell *matHeaderCellDef>Encuestas con duración<br/>fuera de rango de tiempo</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.encuestas_duracion_fuera_de}} </td>
              </ng-container>
    
              <ng-container matColumnDef="n_sin_respuestas_d">
                <th mat-header-cell *matHeaderCellDef>n° preguntas<br/>sin respuesta módulo d</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.rango_tiempo_promedio_preguntas_claves_sin_respuesta}} </td>
              </ng-container>
              <ng-container matColumnDef="n_sin_respuestas_y">
                <th mat-header-cell *matHeaderCellDef>n° preguntas<br/>sin respuesta módulo y</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.rango_tiempo_promedio_preguntas_claves_sin_respuesta}} </td>
              </ng-container>

              <ng-container matColumnDef="n_sin_respuestas_s">
                <th mat-header-cell *matHeaderCellDef>n° reguntas<br/>sin respuesta módulo s</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.rango_tiempo_promedio_preguntas_claves_sin_respuesta}} </td>
              </ng-container>

              <ng-container matColumnDef="claves_sin_respuesta">
                <th mat-header-cell *matHeaderCellDef>Promedio preguntas<br/>claves sin respuesta</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.rango_tiempo_promedio_preguntas_claves_sin_respuesta}} </td>
              </ng-container>

              <!-- Cuando no se encuentran registros. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="disColCalidad.length">
                  Sin información.
                </td>
              </tr>
            
              <tr mat-header-row *matHeaderRowDef="disColCalidad"></tr>
              <tr mat-row *matRowDef="let row; columns: disColCalidad;"></tr>
            </table>
          </mat-card-content>
    
          <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadControlCalidad()">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content>
        </mat-tab>
        <mat-tab label="CONTROL CALIDAD 2">
          <mat-card-content style="margin-top: 20px;">
            <form [formGroup]="frmIndCalidad" style="padding: 20px 0 20px 0;">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Región:</mat-label>
                    <mat-select formControlName="selectRegiones" (selectionChange)="onChangeComboBoxCalidad()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let region of regiones" [value]="region.value">
                        {{region.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 text-center mt-4 mb-2" >
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndCalidad(1)" 
                          value="1" 
                          name="opListCalidad"
                          [checked]="opListCalidad=='1'"
                          >
                          Presencial
                        </mat-radio-button>
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioIndCalidad(2)" 
                          value="2" 
                          name="opListCalidad"
                          >
                          Telefónico
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-card-content>  
          <mat-card-content style="text-align: center;" class="mb-4">
            <button mat-flat-button color="primary" class="button-footer-card" (click)="filtroIndControlCalidad()" style="padding: 0 20px 0 10px;">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">search</mat-icon>
              Buscar
            </button>
          </mat-card-content>
          <mat-card-content style="text-align: center;">
            <table mat-table [dataSource]="dataCalidadDos" class="mat-elevation-z8">
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef>{{textCalidadDos}}</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.rgn_nombre}} </td>
              </ng-container>

              <ng-container matColumnDef="casos_fuera_muestra">
                <th mat-header-cell *matHeaderCellDef>Casos fuera de muestra</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.casos_fuera_muestra}} </td>
              </ng-container>
              
              <ng-container matColumnDef="validacion_hdr">
                <th mat-header-cell *matHeaderCellDef>Casos con problemas de<br/>validación de HDR</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.validacion_hdr}} </td>
              </ng-container>
    
              <ng-container matColumnDef="validacion_cdf">
                <th mat-header-cell *matHeaderCellDef>Casos con problemas de<br/>validación de CDF</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.validacion_cdf}} </td>
              </ng-container>
    
              <!-- Cuando no se encuentran registros. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="disColCalidadDos.length">
                  Sin información.
                </td>
              </tr>
            
              <tr mat-header-row *matHeaderRowDef="disColCalidadDos"></tr>
              <tr mat-row *matRowDef="let row; columns: disColCalidadDos;"></tr>
            </table>
          </mat-card-content>
    
          <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadControlCalidad()">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content>
        </mat-tab>
        <mat-tab label="CONTROL COORDINADOR">
          <mat-card-content class="mt-4">
            <form [formGroup]="frmIndEncuestador" style="padding: 20px 0 20px 0;">
              <div class="row">
                <div class="col-lg-5 col-sm-12">
                  <mat-form-field appearance="fill" style="width:100%; padding: 0 0 0 0;">
                    <mat-label>Coordinadores:</mat-label>
                    <mat-select formControlName="selectCoordinadores" (selectionChange)="onChangeComboBoxCoordinadores()">
                      <mat-option [value]="0">Todas</mat-option>
                      <mat-option *ngFor="let coordinador of coordinadores" [value]="coordinador?.value">
                        {{coordinador?.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </mat-card-content>  
          <mat-card-content style="text-align: center; padding: 0 0 0 0;">
            <button mat-flat-button color="primary" class="button-footer-card" (click)="filtroIndControlCoordinador()" style="padding: 0 20px 0 10px;">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">search</mat-icon>
              Buscar
            </button>
          </mat-card-content>
          <mat-card-content style="text-align: center;">
            <table mat-table [dataSource]="dataCoordinador" class="mat-elevation-z8">
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef>{{textNombreCoordinador}}</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.usu_nombre}} </td>
              </ng-container>
    
              <ng-container matColumnDef="preguntas_sin_respuesta">
                <th mat-header-cell *matHeaderCellDef>Promedio de preguntas<br/>sin respuesta</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.promedio_preguntas_sin_respuesta}} </td>
              </ng-container>
              
              <ng-container matColumnDef="fuera_de_fecha">
                <th mat-header-cell *matHeaderCellDef>Encuestas fuera<br/>de fecha</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.encuestas_fuera_de_fecha}} </td>
              </ng-container>
    
              <ng-container matColumnDef="fuera_rango_tiempo">
                <th mat-header-cell *matHeaderCellDef>Encuestas con duración<br/>fuera de rango de tiempo</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.encuestas_duracion_fuera_de_rango_tiempo}} </td>
              </ng-container>
              
              <ng-container matColumnDef="n_sin_respuestas_d">
                <th mat-header-cell *matHeaderCellDef>n° preguntas<br/>sin respuesta módulo d</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.rango_tiempo_promedio_preguntas_claves_sin_respuesta}} </td>
              </ng-container>
              <ng-container matColumnDef="n_sin_respuestas_y">
                <th mat-header-cell *matHeaderCellDef>n° preguntas<br/>sin respuesta módulo y</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.rango_tiempo_promedio_preguntas_claves_sin_respuesta}} </td>
              </ng-container>

              <ng-container matColumnDef="n_sin_respuestas_s">
                <th mat-header-cell *matHeaderCellDef>n° reguntas<br/>sin respuesta módulo s</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.rango_tiempo_promedio_preguntas_claves_sin_respuesta}} </td>
              </ng-container>

              <ng-container matColumnDef="claves_sin_respuesta">
                <th mat-header-cell *matHeaderCellDef>Promedio preguntas<br/>claves sin respuesta</th>
                <td mat-cell *matCellDef="let element" width="15%" align="center"> {{element.promedio_preguntas_claves_sin_respuesta}} </td>
              </ng-container>

              <!-- Cuando no se encuentran registros. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="disColCoordinador.length">
                  Sin información.
                </td>
              </tr>
            
              <tr mat-header-row *matHeaderRowDef="disColCoordinador"></tr>
              <tr mat-row *matRowDef="let row; columns: disColCoordinador;"></tr>
        
            </table>
          </mat-card-content>
          <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
            <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadControlCoordinador()">
              <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
              <b>DESCARGAR CONSOLIDADO</b>
            </button>
          </mat-card-content>
        </mat-tab>
        <mat-tab label="ALERTA">
          <mat-card-content style="text-align: center;" class="mt-4">
            <form [formGroup]="frmIndAlertas" style="padding: 20px 0 20px 0;">  
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 text-center mt-4 mb-2" >
                      <!-- <mat-radio-group aria-label="Select an option">
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioTipoUser(1)" 
                          value="1" 
                          name="opTipoUser"
                          [checked]="opTipoUser=='1'"
                          >
                          Coordinador
                        </mat-radio-button>
                        <mat-radio-button style="padding: 0 20px 0 30px;" (change)="onChangeRadioTipoUser(2)" 
                          value="2" 
                          name="opTipoUser"
                          >
                          Encuestador
                        </mat-radio-button>
                      </mat-radio-group> -->
                    </div>
                  </div>
                </div>
            </form>
            <div class="row">
              <mat-tab-group animationDuration="1000ms">
                <mat-tab label="ALERTA ENCUESTAS BAJA DURACIÓN">
                  <mat-card-content style="margin-top: 20px;">
                    <table mat-table [dataSource]="dataBajaDuracion" class="mat-elevation-z8">
                      <!-- ['folio_casen', 'region', 'direccion', 'fono', 'coordinador', 'encuestador'] -->
                      <ng-container matColumnDef="folio_casen">
                        <th mat-header-cell *matHeaderCellDef>FOLIO CASEN</th>
                        <td mat-cell *matCellDef="let element" width="10%" align="center">{{element.folio}}</td>
                      </ng-container>
                      <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef>REGIÓN</th>
                        <td mat-cell *matCellDef="let element" width="25%" align="left">{{element.region}}</td>
                      </ng-container>
                      <ng-container matColumnDef="direccion">
                        <th mat-header-cell *matHeaderCellDef>DIRECCIÓN</th>
                        <td mat-cell *matCellDef="let element" width="25%" align="left">{{element.direccion}}</td>
                      </ng-container>
                      <ng-container matColumnDef="fono">
                        <th mat-header-cell *matHeaderCellDef>FONO</th>
                        <td mat-cell *matCellDef="let element" width="10%" align="center">{{element.telefono}}</td>
                      </ng-container>
                      <ng-container matColumnDef="coordinador">
                        <th mat-header-cell *matHeaderCellDef>COORDINADOR</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="left">{{element.nombre_coordinador}}</td>
                      </ng-container>
                      <ng-container matColumnDef="encuestador">
                        <th mat-header-cell *matHeaderCellDef>ENCUESTADOR</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="left">{{element.nombre_encuestador}}</td>
                      </ng-container>
                      <!-- Cuando no se encuentran registros. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="disAlertaGPS.length">
                          Sin información.
                        </td>
                      </tr>
                    
                      <tr mat-header-row *matHeaderRowDef="disAlertaGPS"></tr>
                      <tr mat-row *matRowDef="let row; columns: disAlertaGPS;"></tr>
                    </table>
                  </mat-card-content>  
                </mat-tab>
                <mat-tab label="ALERTA GPS">
                  <br/>
                  <p style="text-align: left;"><b>- Se considera Alerta GPS distancia mayor a 200 metros respecto de GPS Encuesta CASEN</b></p>
                  <mat-card-content style="margin-top: 20px;">
                    <table mat-table [dataSource]="dataAlertaGPS" class="mat-elevation-z8">
                      <ng-container matColumnDef="folio_casen">
                        <th mat-header-cell *matHeaderCellDef>FOLIO CASEN</th>
                        <td mat-cell *matCellDef="let element" align="left"></td>
                      </ng-container>
                      <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef>REGIÓN</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
                      <ng-container matColumnDef="direccion">
                        <th mat-header-cell *matHeaderCellDef>DIRECCIÓN</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
                      <ng-container matColumnDef="fono">
                        <th mat-header-cell *matHeaderCellDef>FONO</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
                      <ng-container matColumnDef="coordinador">
                        <th mat-header-cell *matHeaderCellDef>COORDINADOR</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
                      <ng-container matColumnDef="encuestador">
                        <th mat-header-cell *matHeaderCellDef>ENCUESTADOR</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
        
                      <!-- Cuando no se encuentran registros. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="disBajaDuracion.length">
                          Sin información.
                        </td>
                      </tr>
                    
                      <tr mat-header-row *matHeaderRowDef="disBajaDuracion"></tr>
                      <tr mat-row *matRowDef="let row; columns: disBajaDuracion;"></tr>
                    </table>
                  </mat-card-content>  
                </mat-tab>
                <mat-tab label="ALERTA PREGUNTAS CLAVE SIN RESPUESTA">
                  <mat-card-content style="margin-top: 20px;">
                    <table mat-table [dataSource]="dataPreguntasClaves" class="mat-elevation-z8">
                      <ng-container matColumnDef="folio_casen">
                        <th mat-header-cell *matHeaderCellDef>FOLIO CASEN</th>
                        <td mat-cell *matCellDef="let element" align="left"></td>
                      </ng-container>
                      <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef>REGIÓN</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
                      <ng-container matColumnDef="direccion">
                        <th mat-header-cell *matHeaderCellDef>DIRECCIÓN</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
                      <ng-container matColumnDef="fono">
                        <th mat-header-cell *matHeaderCellDef>FONO</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
                      <ng-container matColumnDef="coordinador">
                        <th mat-header-cell *matHeaderCellDef>COORDINADOR</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
                      <ng-container matColumnDef="encuestador">
                        <th mat-header-cell *matHeaderCellDef>ENCUESTADOR</th>
                        <td mat-cell *matCellDef="let element" width="15%" align="center"></td>
                      </ng-container>
        
                      <!-- Cuando no se encuentran registros. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="disPreguntasClaves.length">
                          Sin información.
                        </td>
                      </tr>
                    
                      <tr mat-header-row *matHeaderRowDef="disPreguntasClaves"></tr>
                      <tr mat-row *matRowDef="let row; columns: disPreguntasClaves;"></tr>
                    </table>
                  </mat-card-content> 
                  <mat-card-content style="text-align: right; padding: 20px 20px 20px 0;">
                    <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;"
                    >
                    <!-- [disabled]="true" -->
                      <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
                      <b>DESCARGAR ALERTA</b>
                    </button>
                  </mat-card-content> 
                </mat-tab>
              </mat-tab-group>
            </div>
          </mat-card-content>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-card>