import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
// import { AdminRoutingModule } from './home-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { TopNavComponent } from './layout/top-nav/top-nav.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { FormEncuestasComponent } from './asignacion-encuestas/form-encuestas/form-encuestas.component';
import { AsignacionEncuestasModule } from './asignacion-encuestas/asignacion-encuestas.module';
import { ReportesModule } from './reportes/reportes.module';
import { EncuestasCoordinadorRoutingModule } from './encuestas-coordiandores/encuestas-coordinador-routing.module';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    AsignacionEncuestasModule,
    ReportesModule,
    EncuestasCoordinadorRoutingModule
  ],
  declarations: [
    
    // LayoutComponent, 
    // TopNavComponent, 
    // SideNavComponent
  ]
})
export class HomeModule {}
