import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MostrarEncuestas } from 'src/app/core/service/mostrar-encuentas.service';
import { AsignacionEncuestasService } from 'src/app/core/service/asignacion-encuestas.service';
import { FormComponent } from './form/form.component';
import { MatPaginator } from '@angular/material/paginator';
import { Encuesta } from '../models/table-encuentas.model';
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { UnsubscribeOnDestroyAdapter } from 'src/app/components/shared/UnsubscribeOnDestroyAdapter';
import { IncidenteModalComponent } from '../modals/incidente/incidente-modal.component';
import { TypesServices } from 'src/app/core/service/types.service';
import Swal from 'sweetalert2';

export interface Encuestador {
  key: number;
  name: string;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

@Component({
  selector: 'app-table-encuestas',
  templateUrl: './table-encuestas.component.html',
  styleUrls: ['./table-encuestas.component.scss']
})
export class TableEncuestasCoordinadoresComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  public isTblLoading = true;
  noData: string = 'Sin información';
  modalObjetDetalle: object;
  nombreEncuestador: string = 'Encuestador';
  exampleDatabase: MostrarEncuestas | null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;

  // displayedColumns: string[] = ['Numero', 'ID', 'Fecha', 'FechaTope', 'FechaAsignacion', 'Dias-Restantes', 'Estado', 'Incidente', 'Ubicacion', 'Detalle'];
  displayedColumns: string[] = ['Numero', 'ID', 'Fecha','FechaAsignacion', 'Dias-Restantes', 'Estado','Detalle'];
  encuestas = []

  dataSource: TablaEncuentaDataSource | null;
  idPerfil: number = parseInt(localStorage.getItem('idPerfil'));
  idUsuarioLocal: string = localStorage.getItem('idUsuario');
  idUsuario: string = localStorage.getItem('idUsuario');

  isCoordinadorCadem: boolean = this.idPerfil == 2 || this.idPerfil == 1 ? true : false;
  
  //Incidencias
  incidencias :any = [];

  // Autocomplete
  myControl = new FormControl('');
  options: Encuestador[] = [];
  filteredOptions: Observable<Encuestador[]>;

  constructor(public httpClient: HttpClient,
    public dialog: MatDialog
    , private mostrarEncuestasService: MostrarEncuestas
    , private asignacionEncuestasService: AsignacionEncuestasService
    , private types: TypesServices) {
    super();
  }

  // console.log() -- 1	Administrador -- 2	Coordinador Cadem -- 3	Coordinador Regional -- 4	Cliente -- 5	Encuestador

  ngAfterViewInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        let returnFilter = []
        if (typeof value === 'number') {
          returnFilter = this._filterKey(value || 0);
        } else {
          returnFilter = this._filter(value || '');
        }
        return returnFilter;
      }),
    );

    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    //console.log(this._ac)
    if (this.idPerfil === 3) {
      this.loadData(this.idUsuarioLocal);
      // this.getServicesEncuentasUser(this.idUsuario);
    } else {
      this.loadData("0");
      this.getCoordinadores(); // Obtener todos los coordinadores.
    }
    this.refreshTable();
    // this.getIncidentes();
  }
  refresh() {
    this.loadData();
  }
  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  private _filter(value: any): Encuestador[] {
    const filterValue = value.toLowerCase();
    const filterData = this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    return filterData;
  }
  private _filterKey(value: any): Encuestador[] {
    const filterValue = value;
    const filterData = this.options.filter(option => option.key === filterValue);
    return filterData;
  }

  displayFn(user: Encuestador): string {
    return user && user.name ? user.name : '';
  }

  // getIncidentes() {
  //   this.types.getIncidentes().subscribe(
  //     ({data, msg, status}) => {
  //       if( parseInt(status) === 200 ){
  //         console.log(data)
  //         this.incidencias = data;
  //       } else {
  //         console.error(msg)
  //       }
  //     }
  //   );
  // }

  getCoordinadores() {
    const idPerfil = `3`
    this.asignacionEncuestasService.getUsers(this.idUsuarioLocal, idPerfil).subscribe(
      ({ data, msg, status }) => {
        if (parseInt(status) === 200) {
          data.map((user: any) => {
            return this.options.push({ key: user.id_user, name: user.nombre_user.toUpperCase() });
          });
        } else {
          console.error(msg);
        }
      });
  }



  public loadData(idUsuario: string = "0") {
    // console.log("Acá...");
    this.exampleDatabase = new MostrarEncuestas(this.httpClient);
    this.idUsuario = idUsuario
    this.dataSource = new TablaEncuentaDataSource(
      this.exampleDatabase,
      this.paginator,
      this.sort,
      this.idUsuario
    );
    if (this.filter?.nativeElement) {
      this.subs.sink = fromEvent(this.filter?.nativeElement, 'keyup')
        // .debounceTime(150)
        // .distinctUntilChanged()
        .subscribe(() => {
          if (!this.dataSource) {
            // this.isTblLoading = false;
            return;
          }
          this.isTblLoading = false;
          this.dataSource.filter = this.filter.nativeElement.value;
        }
        );
    } else { this.isTblLoading = false; }
  }

  getServicesEncuentasUser(pIdUser: string = "") {
    this.mostrarEncuestasService.getEncuestas(pIdUser)
  }

  filtrarEncuestasEncuestador() {
    console.log("jahajhsj")
  }

  getColor(estado: string) {
    // console.log(estado);
    let color = ''
    switch (estado) {
      case 'success':
        color = 'color-text-success';
        break;
      case 'warning':
        color = 'color-text-warn';
        break;
      case 'error':
        color = 'color-text-error';
        break;
      case 'primary':
        color = 'color-text-primary'
        break;
    }
    return color;
  }

  getColorDias(estado: string) {
    // console.log(estado);
    let color = ''
    switch (estado) {
      case 'success':
        color = 'green-bubble';
        break;
      case 'warning':
        color = 'warning-bubble';
        break;
      case 'error':
        color = 'red-bubble';
        break;
      case 'primary':
        color = 'primary-bubble'
        break;
      default:
        color = 'grey-bubble'
        break;
    }
    return color;
  }
  getEncuestasEncuestador(type : string, data: any) {
    if (type === 'click') {
      console.log('Click: ', data.key);
      this.loadData(data.key);
    } else {
      const {key, name} = this.options.find(option => option.name.toUpperCase() === this.myControl.value);
      this.loadData(`${key}`);
    }
  }

  detailsCall(row) {
    console.log(row)
    let tempDirection;
    if (localStorage.getItem('isRtl') === 'true') {
      tempDirection = 'rtl';
    } else {
      tempDirection = 'ltr';
    }
    this.dialog.open(FormComponent, {
      data: {
        detail: row,
        action: 'details'
      },
      direction: tempDirection,
      // height: '70%',
      width: '50%'
    });
  }

  showModalIncidente(element: any) {
    console.log(element)
    const params = {
      dataEncuesta: element,
      incidencias: this.incidencias
    }

    const dialogRef = this.dialog.open(IncidenteModalComponent, {
      data: params,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      if (result) {
        Toast.fire({
          icon: 'success',
          title: 'Se ha registrado incidente correctamente.'
        });
        if (this.idPerfil === 3) {
          this.loadData(this.idUsuarioLocal);
        } else {
          this.loadData("0");
        }
      }

      
    });
    
    
  }


}

export class TablaEncuentaDataSource extends DataSource<Encuesta> {
  filterChange = new BehaviorSubject('');
  get filter(): string {
    return this.filterChange.value;
  }
  set filter(filter: string) {
    this.filterChange.next(filter);
  }
  filteredData: any[] = [];
  renderedData: Encuesta[] = [];
  constructor(
    public mostrarEncuestasService: MostrarEncuestas,
    public paginator: MatPaginator,
    public _sort: MatSort,
    public idUsuario: string
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Encuesta[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.mostrarEncuestasService.dataChange,
      // this._sort.sortChange,
      this.filterChange,
      this.paginator.page
    ];

    this.mostrarEncuestasService.getEncuestas(this.idUsuario, '3');
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this.mostrarEncuestasService.data
          .slice()
          .filter((resEncuesta: any) => {
            const searchStr = (
              resEncuesta.color_dias
              + resEncuesta.color_estado
              + resEncuesta.dias_diferencia.toString()
              + resEncuesta.dias_restantes
              + resEncuesta.estado
              + resEncuesta.fecha_asignacion
              + resEncuesta.fecha_encuesta_origen
              + resEncuesta.fecha_tope
              + resEncuesta.id_origen.toString()
              + resEncuesta.id_origen_label
              + resEncuesta.id_comuna.toString()
              + resEncuesta.nombre_comuna
              + resEncuesta.id_encuesta.toString()
              + resEncuesta.id_estado.toString()
              + resEncuesta.id_region.toString()
              + resEncuesta.nombre_region
              + resEncuesta.id_user.toString()
              + resEncuesta.msg_encuesta
              + resEncuesta.numero.toString()
            ).toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
          });
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());

        // sortedData.map((encuesta: Encuesta) => {
        //   encuesta.fecha_tope = encuesta.fecha_tope.slice(0, 10);
        //   //encuesta.fecha_encuesta_casen = encuesta.fecha_encuesta_casen.slice(0,10);
        // });
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this.paginator.pageSize
        );
        return this.renderedData;
      })
    );
  }
  disconnect() { }
  /** Returns a sorted copy of the database data. */
  sortData(data: Encuesta[]): Encuesta[] {
    // return [];
    return data;
    // if (!this._sort.active || this._sort.direction === '') {
    // }
    // return data.sort((a, b) => {
    //   let propertyA: number | string = '';
    //   let propertyB: number | string = '';
    //   switch (this._sort.active) {
    //     case 'id':
    //       [propertyA, propertyB] = [a.dte_nombre, b.dte_nombre];
    //       break;
    //     // case 'name':
    //     //   [propertyA, propertyB] = [a.name, b.name];
    //     //   break;
    //     // case 'email':
    //     //   [propertyA, propertyB] = [a.email, b.email];
    //     //   break;
    //     // case 'birthDate':
    //     //   [propertyA, propertyB] = [a.birthDate, b.birthDate];
    //     //   break;
    //     // case 'address':
    //     //   [propertyA, propertyB] = [a.address, b.address];
    //     //   break;
    //   }
    //   const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
    //   const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
    //   return (
    //     (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1)
    //   );
    // });
  }
}